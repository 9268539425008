<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-col>
        <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
        <v-card
          class="mb-1 pa-4"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
          outlined
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="dbData"
            item-key="template_id"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            style="background: transparent"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().template
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$store.getters.getTextMap().search"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().templates
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-btn
                  class="mx-1"
                  :color="$store.getters.getColorPalette().accentCode"
                  small
                  text
                  outlined
                  v-on:click="editTemplate(item)"
                  >{{ $store.getters.getTextMap().edit }}

                  <v-icon
                    right
                    :isDark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().deletebtnColor"
                    small
                    >mdi-pencil</v-icon
                  >
                </v-btn>

                <DeleteConfirmation
                  v-on:confirm="deleteTemplate(item)"
                  title="Delete Confirmation"
                  description="Are you sure you want to delete this Log?"
                >
                  <v-btn
                    class="mx-1"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    outlined
                  >
                    {{ $store.getters.getTextMap().delete }}

                    <v-icon
                      right
                      :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor"
                      small
                      >mdi-trash-can</v-icon
                    >
                  </v-btn>
                </DeleteConfirmation>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import axios from "axios";
import moment from "moment";
export default {
  name: "ManufacturingLogbookTemplateList",
  props: ["refetchData"],
  components: {
    InfoAlert,
    DeleteConfirmation,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      expanded: [],
      singleExpand: false,
      sortDesc: false,
      sortBy: "created",
      search: "",
      dbData: [],
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Document Number",
          value: "doc_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Version Number",
          value: "version_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Version Date",
          value: "version_date",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Issue Number",
          value: "issue_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "issue Date",
          value: "issue_date",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Description",
          value: "msg",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Created",
          value: "created",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "manufacturingLogbookTemplates", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            // this.dbData = response.data.data;
            let tempList = [];
            for (let i of response.data.data) {
              let temp = i;
              temp["created"] = i.created
                ? moment(i.created).format("DD-MM-YYYY HH:mm")
                : "-";
              temp["version_date"] = i.version_date
                ? moment(i.version_date).format("DD-MM-YYYY")
                : null;
              temp["issue_date"] = i.issue_date
                ? moment(i.issue_date).format("DD-MM-YYYY")
                : null;
              temp["issue_no"] = i.issue_no ? i.issue_no : "-";
              temp["version_no"] = i.version_no ? i.version_no : "-";
              tempList.push(temp);
            }
            this.dbData = tempList;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.info = err;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    editTemplate(item) {
      this.$emit("editTemplate", item);
      // this.edit = true;
      // this.selectedTemplate = item;
      // this.name = item.name;
      // this.msg = item.msg;
      // this.doc_no = item.doc_no;
      // this.version_no = item.version_no;
      // this.version_date = item.version_date;
      // this.issue_no = item.issue_no;
      // this.issue_date = item.issue_date;
      // this.table_json = item.table_json;
      // this.tableData = [];
      // axios
      //   .post(
      //     this.$store.state.api + "manufacturingLogbookTemplateMetaById",
      //     { template_id: item.template_id },
      //     {
      //       headers: {
      //         Authorization: "Bearer " + this.$store.state.jwt,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     response.data.data.sort((a, b) => a.seq - b.seq);
      //     this.tableData = response.data.data;
      //   });
      // this.dialog = true;
    },
    deleteTemplate(d) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "deleteManufacturingLogbookTemplate",
          { template_id: d.template_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.info = "Logbook Template Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
            this.getData();
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
  },
  watch: {
    refetchData: {
      handler() {
        if (this.refetchData) {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
