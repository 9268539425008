var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding",attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e(),(!_vm.loading)?_c('v-row',{staticClass:"mb-4 mt-1",attrs:{"no-gutters":""}},_vm._l((_vm.groupedData),function(items,tableName){return _c('v-col',{key:tableName,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-1",style:({
          border:
            '1px solid' +
            _vm.$store.getters.getColorPalette().panelBorderColorCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode,"outlined":""}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(tableName)+" ")]),_c('v-card-text',[_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-data-table',{attrs:{"headers":_vm.generateHeaders(items),"items":_vm.generateRows(items),"item-key":_vm.key-_vm.value,"search":_vm.search,"single-expand":false,"loading":_vm.loading,"loading-text":"Loading... Please wait","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorName},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().reference)+" "+_vm._s(_vm.$store.getters.getTextMap().sheet)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.enture_ed",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""},on:{"click":function($event){return _vm.editRow({
                      meta: item,
                      table_name: tableName,
                      key_value: item.key_value,
                    })}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().edit)+" "),_c('v-icon',{attrs:{"right":"","isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().deletebtnColor,"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.enture_del",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this Log?"},on:{"confirm":function($event){return _vm.deleteRow({
                      table_name: tableName,
                      key_value: item.key_value,
                    })}}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().delete)+" "),_c('v-icon',{attrs:{"right":"","isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().deletebtnColor,"small":""}},[_vm._v("mdi-trash-can")])],1)],1)],1)]}}],null,true)},[[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().reference)+" "+_vm._s(_vm.$store.getters.getTextMap().sheet)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]],2),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}})],1)],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }