<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-col align="center" align-self="center">
        <v-menu
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              v-model="from_date"
              :label="$store.getters.getTextMap().from_date"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              clearable
              :loading="loading"
              v-bind="attrs"
              v-on="on"
              :rules="ISODateRules"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            :dark="$store.getters.getColorPalette().isDark"
            color="cyan lighten-1"
            v-model="from_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col align="center" align-self="center">
        <v-menu
          :dark="$store.getters.getColorPalette().isDark"
          color="cyan lighten-1"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :dark="$store.getters.getColorPalette().isDark"
              color="cyan lighten-1"
              v-model="to_date"
              :label="$store.getters.getTextMap().to_date"
              prepend-icon="mdi-calendar"
              readonly
              dense
              clearable
              outlined
              :loading="loading"
              v-bind="attrs"
              v-on="on"
              :rules="ISODateRules"
            ></v-text-field>
          </template>
          <v-date-picker
            :dark="$store.getters.getColorPalette().isDark"
            color="cyan lighten-1"
            v-model="to_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
          v-if="!loading"
          v-model="timestamp_type"
          :items="timestampTypeOptions"
          :label="
            $store.getters.getTextMap().timestamp +
            ' ' +
            $store.getters.getTextMap().type
          "
          dense
          outlined
          item-text="label"
          item-value="value"
          :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
          :dark="$store.getters.getColorPalette().isDark"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-if="!loading"
          v-model="status"
          :items="statusOptions"
          :label="$store.getters.getTextMap().status"
          dense
          outlined
          small
          item-text="label"
          item-value="value"
          :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
          :dark="$store.getters.getColorPalette().isDark"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-if="!loading"
          v-model="stage"
          :items="stageOptions"
          :label="$store.getters.getTextMap().stage"
          dense
          outlined
          item-text="label"
          item-value="value"
          :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
          :dark="$store.getters.getColorPalette().isDark"
        ></v-select>
      </v-col>
      <v-col align-items="center">
        <v-btn
          v-if="!loading"
          outlined
          text
          small
          :dark="$store.getters.getColorPalette().isDark"
          @click="getData"
          >{{ $store.getters.getTextMap().submit }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card
          class="mb-1 pa-4"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
          outlined
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <v-data-table
            :headers="headers"
            :items="logbooks"
            item-key="index"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            style="background: transparent"
          >
            <template
              v-slot:top
              style="background: transparent; box-shadow: none"
            >
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().log_book
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$store.getters.getTextMap().search"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>

            <!-- Scoped slot for dynamic colors -->
            <template v-slot:item.status="{ item }">
              <div :style="getStatusStyle(item.status)">
                {{ statusNameMap[item.status] }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div>
                <v-btn
                  :dark="$store.getters.getColorPalette().isDark"
                  small
                  outlined
                  text
                  @click="viewDetails(item)"
                >
                  View Details
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog
          :dark="$store.getters.getColorPalette().isDark"
          v-model="dialog"
          hide-overlay
          fullscreen
          transition="dialog-bottom-transition"
          :style="{
            border: '2px solid ' + $store.getters.getColorPalette().accentCode,
          }"
        >
          <ManufacturingLogbookMakerForm
            :logbookData="logbookData"
            :readOnly="true"
            v-on:closeForm="closeDialog"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import moment from "moment";
import axios from "axios";
import ManufacturingLogbookMakerForm from "@/components/forms/manufacturing/logbook/ManufacturingLogbookMakerForm.vue";
export default {
  name: "ManufacturingHistoricalLogbookList",
  components: {
    InfoAlert,
    ManufacturingLogbookMakerForm,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      logbooks: [],
      search: "",
      sortDesc: false,
      sortBy: "timestamp",
      dialog: false,
      logbookData: {},
      menu1: false,
      menu2: false,
      from_date: null,
      to_date: null,
      fromTime: "00:00",
      toTime: "23:59",
      status: null,
      stage: null,
      timestamp_type: "timestamp",
      timestampTypeOptions: [
        { label: "Created At", value: "timestamp" },
        { label: "Submitted At", value: "submitted_timestamp" },
      ],
      statusOptions: [
        { label: "All", value: null },
        { label: "In Process", value: "in_process" },
        { label: "Rejected", value: "rejected" },
        { label: "Completed", value: "completed" },
        { label: "Refill", value: "refill" },
      ],
      stageOptions: [
        { label: "All", value: null },
        { label: "Maker", value: "maker" },
        { label: "Checker", value: "checker" },
        { label: "Approver", value: "approver" },
      ],
      statusNameMap: {
        in_process: "In Process",
        rejected: "Rejected",
        completed: "Completed",
        refill: "Refill",
      },
      headers: [],
      ISODateRules: [
        (v) => !!v || this.$store.getters.getTextMap().required,
        (v) =>
          /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) ||
          "It should be a Date",
      ],
      objectRules: [(v) => !!v || this.$store.getters.getTextMap().required],
    };
  },
  mounted() {
    this.from_date = moment().subtract(1, "week").format("YYYY-MM-DD");
    this.to_date = moment().format("YYYY-MM-DD");
    this.init();
  },
  computed: {
    fromTimeStamp() {
      if (this.from_date) {
        let dt = this.from_date.toString();
        if (this.fromTime) {
          dt += " " + this.fromTime.toString();
          return Number(moment(dt, "YYYY-MM-DD HH:mm").format("X"));
        }
        return Number(moment(dt, "YYYY-MM-DD").format("X"));
      } else {
        return null;
      }
    },
    toTimeStamp() {
      if (this.to_date) {
        let dt = this.to_date.toString();
        if (this.toTime) {
          dt += " " + this.toTime.toString();
          return Number(moment(dt, "YYYY-MM-DD HH:mm").format("X"));
        }
        return null;
      } else {
        return null;
      }
    },
  },
  methods: {
    init() {
      this.getData();
    },
    viewDetails(logbook) {
      this.logbookData = logbook;
      this.dialog = true;
    },
    closeDialog() {
      this.logbookData = {};
      this.dialog = false;
      this.init();
    },
    getTimeDifference(tz, closure_duration, checklist_type) {
      const givenTime = moment.utc(tz);
      const now = moment.utc();
      const minutesPassed = now.diff(givenTime, "minutes");
      const remainingMinutes = closure_duration - minutesPassed;

      if (remainingMinutes <= 0) {
        return `${checklist_type === "adhoc" ? "-" : "Closed"}`;
      } else if (remainingMinutes > 1440) {
        const remainingDays = Math.round(remainingMinutes / 1440);
        return `${remainingDays} days`;
      } else if (remainingMinutes < 60) {
        return `${remainingMinutes} mins`;
      } else {
        return `${Math.round(remainingMinutes / 60)} hrs`;
      }
    },
    getData() {
      this.loading = true;
      this.checklists = [];
      this.headers = [
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class:
            "font-weight-bold " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Created At",
          align: "start",
          filterable: false,
          value: "timestamp",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Logbook",
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Document Number",
          value: "doc_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Stage",
          value: "stage",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Status",
          value: "status",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Submitted At",
          value: "submitted_timestamp",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ];
      let payload = {
        from_time: this.fromTimeStamp,
        to_time: this.toTimeStamp,
        timestamp_type: this.timestamp_type,
        status: this.status,
        stage: this.stage,
      };
      axios
        .post(
          this.$store.state.api + "getManufacturingLogbookBetweenTimestamp",
          payload,
          {
            headers: { Authorization: "Bearer " + this.$store.state.jwt },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            let dbData = [...response.data.data];
            let tempList = [];
            let submitted_by_present = false;
            // let rejected_by_present = false;
            let approved_by_present = false;
            let checked_by_present = false;
            let issued_by_present = false;
            let fill_started_by = false;
            let refilled_by_present = false;
            let index = 0;
            for (let i of dbData) {
              let temp = i;
              temp["index"] = index++;
              temp["time_remaining_for_auto_close"] = this.getTimeDifference(
                i.timestamp,
                i.closure_duration,
                i.schedule
              );
              temp["timestamp"] = moment(i.timestamp).format(
                "DD-MM-YYYY HH:mm"
              );
              temp["submitted_timestamp"] = i.submitted_timestamp
                ? moment(i.submitted_timestamp).format("DD-MM-YYYY HH:mm")
                : "-";
              temp["fill_start_timestamp"] = i.fill_start_timestamp
                ? moment(i.fill_start_timestamp).format("DD-MM-YYYY HH:mm")
                : "-";
              temp["version_date"] = i.version_date
                ? moment(i.version_date).format("DD-MM-YYYY")
                : "-";
              temp["issue_date"] = i.issue_date
                ? moment(i.issue_date).format("DD-MM-YYYY")
                : "-";

              if (i.submitted_by) {
                submitted_by_present = true;
              }
              if (i.approved_by) {
                approved_by_present = true;
              }
              if (i.checked_by) {
                checked_by_present = true;
              }
              if (i.issued_by) {
                issued_by_present = true;
              }
              if (i.fill_started_by) {
                fill_started_by = true;
              }
              if (i.refilled_by) {
                refilled_by_present = true;
              }
              if (i.auto_submitted) {
                temp["submitted_by"] = "System";
              }
              if (i.approver_remarks) {
                temp["approver_remarks"] = i.approver_remarks;
              }
              if (i.checker_remarks) {
                temp["checker_remarks"] = i.checker_remarks;
              }
              tempList.push(temp);
            }
            this.headers.push({
              text: "Auto Close In",
              value: "time_remaining_for_auto_close",
              class:
                " text-capitalize font-weight-black " +
                this.$store.getters.getColorPalette().tableHeaderColorName,
            });
            if (
              fill_started_by &&
              !(
                !this.headers.some(
                  (item) => item.value === "fill_start_timestamp"
                ) &&
                this.headers.some((item) => item.value === "fill_started_by")
              )
            ) {
              this.headers.push({
                text: "Started By",
                value: "fill_started_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
              this.headers.push({
                text: "Started At",
                value: "fill_start_timestamp",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              submitted_by_present &&
              !this.headers.some((item) => item.value === "submitted_by")
            ) {
              this.headers.push({
                text: "Submitted By",
                value: "submitted_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              approved_by_present &&
              !this.headers.some((item) => item.value === "approved_by")
            ) {
              this.headers.push({
                text: "Approved By",
                value: "approved_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              checked_by_present &&
              !this.headers.some((item) => item.value === "checked_by")
            ) {
              this.headers.push({
                text: "Checked By",
                value: "checked_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              issued_by_present &&
              !this.headers.some((item) => item.value === "issued_by")
            ) {
              this.headers.push({
                text: "Issued By",
                value: "issued_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              refilled_by_present &&
              !this.headers.some((item) => item.value === "refilled_by")
            ) {
              this.headers.push({
                text: "Refilled By",
                value: "refilled_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            this.logbooks = tempList;
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getStatusStyle(status) {
      return {
        width: "80px", // Fixed width for all statuses
        padding: "5px",
        backgroundColor: this.getBackgroundColor(status),
        color: this.getTextColor(status),
        fontWeight: "bold",
        textAlign: "center",
        display: "inline-block", // Ensures the box respects width
        lineHeight: "20px", // Adjust to vertically center the text
      };
    },
    getBackgroundColor(status) {
      switch (status) {
        case "completed":
          return "#C8E6C9"; // Green
        case "rejected":
          return "#FFCDD2"; // Red
        case "refill":
          return "#FFECB3"; // Yellow
        case "in_process":
          return "#C5CAE9"; // Blue
        default:
          return "black"; // Default color
      }
    },
    getTextColor(status) {
      switch (status) {
        case "completed":
          return "#43A047"; // Green
        case "rejected":
          return "#E53935"; // Red
        case "refill":
          return "#FFB300"; // Yellow
        case "in_process":
          return "#3949AB"; // Blue
        default:
          return "black"; // Default color
      }
    },
  },
  watch: {
    timestamp_type: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
