<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
    />
    <div v-if="startScanning && !disableForm">
      <v-dialog
        v-model="startScanning"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          flat
          outlined
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <QRCodeScanner
            :fieldData="fieldData"
            v-on:qrData="addQRData"
            v-on:goBack="toggleScan"
          />
        </v-card>
      </v-dialog>
    </div>
    <div v-if="startImageUpload && !disableForm">
      <v-dialog
        v-model="startImageUpload"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          flat
          outlined
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <OCRComponent
            :fieldData="fieldData"
            v-on:ocrData="addOCRData"
            v-on:goBack="toggleImageComponent"
          />
        </v-card>
      </v-dialog>
    </div>
    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
    <v-card
      v-else
      class="pa-5"
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
      flat
    >
      <div class="d-flex justify-space-between">
        <h3 class="py-4">
          {{ logbookData.name }}
        </h3>
        <div>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="$emit('closeForm')"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-row
        class="no-padding mb-1"
        v-for="(item, main_index) in templateMeta"
        :key="main_index"
        no-gutters
      >
        <v-col
          ><v-col cols="12">
            <v-card
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                border:
                  '1px solid ' +
                  $store.getters.getColorPalette().panelBorderColorCode,
              }"
              :color="$store.getters.getColorPalette().backgroundColorCode"
            >
              <v-card-title class="d-flex justify-space-between">
                <span>{{ item.table_key }} ({{ item.table_type }})</span>
              </v-card-title>
              <v-card-text
                ><v-simple-table
                  fixed-header
                  dense
                  :style="{
                    'background-color':
                      $store.getters.getColorPalette().backgroundColorCode,
                  }"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(header, header_index) in item.data_meta
                            .headers"
                          :key="header_index"
                          class="text-left"
                          :class="
                            $store.getters.getColorPalette().foregroundColorName
                          "
                          :color="$store.getters.getColorPalette().accentCode"
                        >
                          <strong>{{ header }}</strong>
                        </th>
                        <!-- <th
                          v-if="item.table_type === 'dynamic'"
                          :class="
                            'text-left  text-subtitle-1 text-capitalize ' +
                            $store.getters.getColorPalette().foregroundColorName
                          "
                        >
                          <v-btn
                            small
                            icon
                            @click="editHeader(main_index)"
                            :color="$store.getters.getColorPalette().accentCode"
                          >
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </th> -->
                        <th
                          class="text-left"
                          :class="
                            $store.getters.getColorPalette().foregroundColorName
                          "
                          v-if="
                            item.table_type === 'dynamic' &&
                            logbookData &&
                            logbookData.logbook_id &&
                            logbookData.stage === 'maker' &&
                            !readOnly
                          "
                        >
                          <strong>Actions</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, row_index) in item.data_meta.rows"
                        :key="row_index"
                        :style="{
                          'background-color':
                            $store.getters.getColorPalette()
                              .backgroundColorCode,
                        }"
                      >
                        <td
                          v-for="(cell, cell_index) in row"
                          :key="cell_index"
                          class="text-left"
                          :color="$store.getters.getColorPalette().accentCode"
                        >
                          <!-- Static -->
                          <div v-if="cell.type === 'static'">
                            <span>{{ cell.value }}</span>
                          </div>
                          <!-- Input -->
                          <div v-else-if="cell.type === 'input'">
                            <!-- Checkbox -->
                            <span v-if="cell.value.type === 'check'">
                              <v-checkbox
                                :disabled="
                                  (logbookData &&
                                    logbookData.logbook_id &&
                                    logbookData.stage !== 'maker') ||
                                  readOnly
                                "
                                v-model="
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                "
                                hide-details
                                :color="
                                  $store.getters.getColorPalette().accentCode
                                "
                              ></v-checkbox>
                            </span>
                            <!-- Text -->
                            <span v-if="cell.value.type === 'text'">
                              <v-text-field
                                :disabled="
                                  (logbookData &&
                                    logbookData.logbook_id &&
                                    logbookData.stage !== 'maker') ||
                                  readOnly
                                "
                                v-model="
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                "
                                dense
                                placeholder="Type your Input"
                                hide-details
                                class="pa-0"
                              >
                              </v-text-field>
                            </span>
                            <!-- Dependent List Text -->
                            <span
                              v-if="
                                cell.value.type === 'dependent_list_text' &&
                                !loading
                              "
                            >
                              <v-select
                                :disabled="
                                  (logbookData &&
                                    logbookData.logbook_id &&
                                    logbookData.stage !== 'maker') ||
                                  readOnly
                                "
                                v-model="
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                "
                                :items="
                                  getDependentListByQueryKey(
                                    cell.value.meta.list_id,
                                    templateMeta[main_index].data_meta.rows[
                                      row_index
                                    ][cell.value.meta.column_no - 1].value.data
                                  )
                                "
                                item-text="tag"
                                item-value="value"
                                placeholder="Select your Input"
                                dense
                                outlined
                                hide-details
                                class="pa-0"
                              >
                              </v-select>
                            </span>
                            <!-- List Text -->
                            <span
                              v-if="cell.value.type === 'list_text' && !loading"
                            >
                              <v-select
                                :disabled="
                                  (logbookData &&
                                    logbookData.logbook_id &&
                                    logbookData.stage !== 'maker') ||
                                  readOnly
                                "
                                v-model="
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                "
                                :items="listDropdown(cell.value.meta.list_id)"
                                item-text="label"
                                item-value="value"
                                placeholder="Select your Input"
                                dense
                                outlined
                                hide-details
                                class="pa-0"
                              >
                              </v-select>
                            </span>
                            <div v-if="cell.value.type === 'clock' && !loading">
                              <div :style="{ padding: '10px 10px' }">
                                {{
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                    ? templateMeta[main_index].data_meta.rows[
                                        row_index
                                      ][cell_index].value.data.toString() +
                                      " Hr"
                                    : "--:--"
                                }}
                              </div>
                              <div
                                v-if="
                                  logbookData &&
                                  logbookData.logbook_id &&
                                  logbookData.stage === 'maker' &&
                                  !readOnly
                                "
                              >
                                <TimePicker
                                  v-model="
                                    templateMeta[main_index].data_meta.rows[
                                      row_index
                                    ][cell_index].value.data
                                  "
                                />
                                <!-- <v-menu
                                  v-if="!loading"
                                  ref="menufrom"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="
                                    templateMeta[main_index].data_meta.rows[
                                      row_index
                                    ][cell_index].value.data
                                  "
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                  :dark="
                                    $store.getters.getColorPalette().isDark
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      dense
                                      outlined
                                      v-model="
                                        templateMeta[main_index].data_meta.rows[
                                          row_index
                                        ][cell_index].value.data
                                      "
                                      :label="
                                        $store.getters.getTextMap().from_time
                                      "
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :dark="
                                        $store.getters.getColorPalette().isDark
                                      "
                                      :rules="[rules.required]"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu2"
                                    v-model="
                                      templateMeta[main_index].data_meta.rows[
                                        row_index
                                      ][cell_index].value.data
                                    "
                                    full-width
                                    format="24hr"
                                    @click:minute="
                                      $refs.menufrom?.[main_index]?.[
                                        row_index
                                      ]?.save(
                                        templateMeta[main_index].data_meta.rows[
                                          row_index
                                        ][cell_index].value.data
                                      )
                                    "
                                    :dark="
                                      $store.getters.getColorPalette().isDark
                                    "
                                  ></v-time-picker>
                                </v-menu> -->
                              </div>
                            </div>

                            <div
                              :style="{ margin: '10px' }"
                              v-if="cell.value.type === 'calendar' && !loading"
                            >
                              <div
                                :style="{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: '10px',
                                  padding: '10px',
                                }"
                              >
                                <div>
                                  {{
                                    getFormattedDate(
                                      templateMeta[main_index].data_meta.rows[
                                        row_index
                                      ][cell_index].value.data
                                    )
                                  }}
                                </div>
                                <div
                                  v-if="
                                    logbookData &&
                                    logbookData.logbook_id &&
                                    logbookData.stage === 'maker' &&
                                    !readOnly
                                  "
                                >
                                  <v-menu
                                    v-model="
                                      menuState[
                                        `${main_index}-${row_index}-${cell_index}`
                                      ]
                                    "
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                    :dark="
                                      $store.getters.getColorPalette().isDark
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        :dark="
                                          $store.getters.getColorPalette()
                                            .isDark
                                        "
                                      >
                                        <v-icon>mdi-calendar</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        templateMeta[main_index].data_meta.rows[
                                          row_index
                                        ][cell_index].value.data
                                      "
                                      color="cyan lighten-1"
                                      @change="
                                        menuState[
                                          `${main_index}-${row_index}-${cell_index}`
                                        ] = false
                                      "
                                      :dark="
                                        $store.getters.getColorPalette().isDark
                                      "
                                    ></v-date-picker>
                                  </v-menu>
                                </div>
                              </div>
                            </div>

                            <!-- QR Code -->
                            <div
                              v-if="cell.value.type === 'qrtext' && !loading"
                            >
                              <span class="pa-5">
                                {{
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                }}
                              </span>
                              <span
                                v-if="
                                  !startScanning &&
                                  logbookData &&
                                  logbookData.logbook_id &&
                                  logbookData.stage === 'maker' &&
                                  !readOnly
                                "
                                class="ma-2"
                              >
                                <v-btn
                                  :color="
                                    $store.getters.getColorPalette()
                                      .downloadbtnColor
                                  "
                                  outlined
                                  small
                                  @click="
                                    toggleScan({
                                      main_index,
                                      row_index,
                                      cell_index,
                                    })
                                  "
                                  >Scan QR</v-btn
                                >
                              </span>
                            </div>
                            <!-- OCR -->
                            <div v-if="cell.value.type === 'ocr' && !loading">
                              <span class="pa-5">
                                {{
                                  templateMeta[main_index].data_meta.rows[
                                    row_index
                                  ][cell_index].value.data
                                }}
                              </span>
                              <span
                                v-if="
                                  !startScanning &&
                                  logbookData &&
                                  logbookData.logbook_id &&
                                  logbookData.stage === 'maker' &&
                                  !readOnly
                                "
                                class="ma-2"
                              >
                                <v-btn
                                  :color="
                                    $store.getters.getColorPalette()
                                      .downloadbtnColor
                                  "
                                  outlined
                                  small
                                  @click="
                                    toggleImageComponent({
                                      main_index,
                                      row_index,
                                      cell_index,
                                    })
                                  "
                                  >Scan Image</v-btn
                                >
                              </span>
                            </div>
                          </div>
                          <!-- Auto -->
                          <div v-else>
                            <div
                              v-if="
                                cell.value.data &&
                                !(
                                  cell.value.type === 'current_time' ||
                                  cell.value.type === 'param'
                                )
                              "
                            >
                              <div>{{ cell.value.data }}</div>
                              <div>(Auto Generated)</div>
                            </div>
                            <div
                              class="mb-1"
                              v-else-if="cell.value.type === 'current_time'"
                            >
                              <div class="my-1">
                                {{
                                  cell.value.data
                                    ? cell.value.data
                                    : addCurrentTime(
                                        main_index,
                                        row_index,
                                        cell_index,
                                        templateMeta[main_index].data_meta.rows[
                                          row_index
                                        ][cell_index].value.template
                                      )
                                }}
                              </div>
                              <v-btn
                                v-if="logbookData.stage !== 'approver'"
                                color="accent"
                                small
                                @click="
                                  addCurrentTime(
                                    main_index,
                                    row_index,
                                    cell_index,
                                    templateMeta[main_index].data_meta.rows[
                                      row_index
                                    ][cell_index].value.template
                                  )
                                "
                              >
                                <v-icon small>mdi-clock-plus-outline</v-icon>
                              </v-btn>
                            </div>
                            <div v-else-if="cell.value.type === 'param'">
                              <div class="my-1">
                                {{
                                  cell.value.data
                                    ? cell.value.data
                                    : cell.value.template.param_label
                                }}
                              </div>
                              <v-btn
                                v-if="logbookData.stage !== 'approver'"
                                color="accent"
                                small
                                @click="
                                  captureParamData(
                                    main_index,
                                    row_index,
                                    cell_index,
                                    templateMeta[main_index].data_meta.rows[
                                      row_index
                                    ][cell_index].value.template.param_id
                                  )
                                "
                              >
                                <v-icon small>mdi-card-outline</v-icon>
                              </v-btn>
                            </div>
                            <span
                              v-else-if="
                                logbookData &&
                                logbookData.logbook_id &&
                                logbookData.stage === 'maker' &&
                                !readOnly
                              "
                              >This field will be auto filled</span
                            >
                            <span v-else></span>
                          </div>
                        </td>
                        <!-- <td
                          v-if="item.table_type === 'dynamic'"
                          class="text-left text-capitalize"
                        >
                          <v-btn
                            small
                            @click="editRowItem(row_index, main_index)"
                            icon
                            color="green"
                          >
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </td> -->
                        <td
                          v-if="
                            item.table_type === 'dynamic' &&
                            logbookData &&
                            logbookData.logbook_id &&
                            logbookData.stage === 'maker' &&
                            !readOnly
                          "
                          class="text-right text-capitalize"
                        >
                          <v-btn
                            icon
                            :color="
                              $store.getters.getColorPalette().deletebtnColor
                            "
                          >
                            <DeleteConfirmation
                              v-on:confirm="deleteRow(row_index, main_index)"
                              title="Delete Confirmation"
                              description="Are you sure you want to delete this?"
                            >
                              <v-icon small>mdi-delete</v-icon>
                            </DeleteConfirmation>
                          </v-btn>
                        </td>
                      </tr>
                      <tr v-if="item.table_type === 'dynamic'">
                        <!-- <td>
                          <v-btn
                            class="my-1"
                            :dark="$store.getters.getColorPalette().isDark"
                            @click="addRow(main_index)"
                            small
                            text
                            outlined
                            >Add Row
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </td> -->
                        <td
                          v-if="
                            logbookData &&
                            logbookData.logbook_id &&
                            logbookData.stage === 'maker' &&
                            !readOnly
                          "
                        >
                          <v-btn
                            class="my-1"
                            :dark="$store.getters.getColorPalette().isDark"
                            @click="replicateRow(main_index, 0)"
                            small
                            text
                            outlined
                            >Add Row
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <div v-if="refillRequest && logbookData.stage !== 'maker' && !readOnly">
        <v-row>
          <v-col>
            <h2 :style="{ color: $store.getters.getColorPalette().accentCode }">
              {{ $store.getters.getTextMap().refill_request }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form ref="refillForm" v-model="isFormValid">
              <v-select
                v-if="!loading"
                v-model="req_assignee"
                :items="userList"
                :label="$store.getters.getTextMap().assignee"
                dense
                outlined
                item-text="label"
                item-value="value"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                :rules="[rules.required]"
                :dark="$store.getters.getColorPalette().isDark"
              ></v-select>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right">
            <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
            <!-- <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="moveStage(false)">{{$store.getters.getTextMap().accept}}</v-btn> -->
            <v-btn
              class="mx-1"
              v-if="!loading"
              :color="$store.getters.getColorPalette().cancelbtnColor"
              small
              @click="refillRequest = false"
              >{{ $store.getters.getTextMap().cancel }}</v-btn
            >
            <v-btn
              v-if="!loading"
              :color="$store.getters.getColorPalette().submitbtnColor"
              small
              @click="refillSubmit"
              >{{ $store.getters.getTextMap().submit }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-row
        v-if="
          logbookData &&
          logbookData.logbook_id &&
          logbookData.stage === 'maker' &&
          !readOnly
        "
      >
        <v-col align="right">
          <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            @click="submitForm(false)"
            >{{
              isFilledByPowerMaker()
                ? "save & release lock"
                : $store.getters.getTextMap().save
            }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="!refillRequest && !readOnly">
        <v-col align="right">
          <v-text-field
            dense
            outlined
            v-model="remarks"
            :label="$store.getters.getTextMap().remarks"
          ></v-text-field>
        </v-col>
        <v-col align="right">
          <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
          <!-- <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="moveStage(false)">{{$store.getters.getTextMap().accept}}</v-btn> -->
          <v-btn
            class="mx-1"
            v-if="!loading"
            :color="$store.getters.getColorPalette().cancelbtnColor"
            small
            @click="moveStage(true)"
            >{{ $store.getters.getTextMap().reject }}</v-btn
          >
          <v-btn
            class="mx-1"
            v-if="!loading"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            @click="refillRequest = true"
            >{{ $store.getters.getTextMap().refill_request }}</v-btn
          >
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            @click="moveStage(false)"
            >{{ $store.getters.getTextMap().accept }} And
            {{ $store.getters.getTextMap().move_to_next_stage }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="!readOnly">
        <v-col>
          <GetManufacturingLogbookFieldModal
            :show="rowEdit"
            v-on:close="rowEdit = false"
            v-on:value="updateRowItem"
          />
        </v-col>
      </v-row>
      <v-row v-if="!readOnly">
        <v-col>
          <GetTextModal
            :show="headerEdit"
            v-on:close="headerEdit = false"
            v-on:value="updateHeader"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { io } from "socket.io-client";
import axios from "axios";
import moment from "moment";
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert.vue";
import QRCodeScanner from "@/components/reusable_components/QRCodeScanner.vue";
import OCRComponent from "@/components/reusable_components/OCRComponent.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import GetTextModal from "@/components/modals/GetTextModal";
import GetManufacturingLogbookFieldModal from "@/components/modals/manufacturing/logbook/GetManufacturingLogbookFieldModal.vue";
import TimePicker from "@/components/inputs/TimePicker";
// import { evaluate } from "mathjs";
// import ImageComponent from "@/components/reusable_components/ImageComponent.vue";
export default {
  name: "ManufacturingLogbookMakerForm",
  props: ["logbookData", "readOnly"],
  components: {
    InfoAlert,
    QRCodeScanner,
    OCRComponent,
    GetTextModal,
    GetManufacturingLogbookFieldModal,
    DeleteConfirmation,
    TimePicker,
    // ImageComponent,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      menuState: {},
      menu2: false,
      menu1: false,
      isFormValid: false,
      templateMeta: [],
      fieldData: null,
      startScanning: false,
      startImageUpload: false,
      rowEdit: false,
      headerEdit: false,
      rowEditIndex: null,
      mainEditIndex: null,
      fill_time: null,
      firstRow: [],
      userList: [],
      paramList: [],
      remarks: null,
      refillRequest: false,
      req_assignee: null,
      rules: FormRules.rules,
      copiedData: [],
      stream: null,
      streamData: {},
      dateTimeFormatMap: {
        "YYYY-MM-DD": "YYYY-MM-DD",
        "YYYY-MM-DD HH:mm": "YYYY-MM-DD HH:mm",
        "DD-MM-YYYY HH:mm": "DD-MM-YYYY HH:mm",
        "DD-MM-YYYY": "DD-MM-YYYY",
        "MM-YYYY": "MM-YYYY",
        "DD-MM": "DD-MM",
        "HH:MM": "HH:mm",
        "HH:mm": "HH:mm",
      },
      ISODateRules: [
        (v) => !!v || this.$store.getters.getTextMap().required,
        (v) =>
          /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) ||
          "It should be a Date",
      ],
      objectRules: [(v) => !!v || "Required"],
    };
  },
  created() {
    this.stream = io(this.$store.state.streamApi, {
      withCredentials: true,
      auth: { token: this.$store.state.jwt },
    });
  },
  beforeDestroy() {
    this.stream.close();
  },
  mounted() {
    this.init();
  },
  computed: {
    shiftData() {
      return this.$store.getters.getShiftDataById(this.logbookData.shift_id);
    },
  },
  methods: {
    subscribe() {
      // if (this.paramList && this.paramList.length > 0) {
      //   this.paramList.forEach((param) => {
      //     this.stream.emit("getParam", param);
      //   });
      // }
      if (this.paramList && this.paramList.length > 0) {
        this.paramList.forEach((param) => {
          if (param.type == "rtd") {
            this.stream.emit("getParam", param.param_id);
          } else if (param.type == "rtc") {
            this.stream.emit("getParamConsumption", param.param_id);
          }
        });
      }
    },
    streamInit() {
      this.stream.on("connect_error", (err) => {
        console.error(err.message);
      });
      this.stream.on("error", (data) => {
        console.error(data);
      });
      this.stream.on("param", (data) => {
        if (data.constructor == String) {
          data = JSON.parse(data);
        }
        let temp = Object.assign({}, this.streamData);
        temp[data.param_id] = Math.round(Number(data.data) * 1000) / 1000;
        this.streamData = temp;
      });

      this.stream.on("paramConsumption", (data) => {
        if (data.constructor == String) {
          data = JSON.parse(data);
        }
        let temp = Object.assign({}, this.streamData);
        temp[data.param_id] = Math.round(Number(data.data) * 1000) / 1000;
        this.streamData = temp;
      });
      this.subscribe();
    },
    captureParamData(main_index, row_index, cell_index, param_id) {
      this.loading = true;
      let streamData = this.streamData;

      let value = streamData[param_id];
      this.templateMeta[main_index].data_meta.rows[row_index][
        cell_index
      ].value.data = value;
      this.loading = false;
    },
    init() {
      this.loading = true;
      this.fill_time = moment();
      if (
        this.logbookData &&
        this.logbookData.logbook_id &&
        this.logbookData.template_id
      ) {
        this.getMakersList();
        this.getLogbookMetaData();
      } else {
        this.loading = false;
      }
    },
    editHeader(main_index) {
      this.mainEditIndex = main_index;
      this.headerEdit = true;
    },
    updateHeader(value) {
      let temp = [...this.templateMeta];
      temp[this.mainEditIndex].data_meta.headers.push(value);
      this.templateMeta = temp;
    },
    addRow(main_index) {
      this.loading = true;
      this.templateMeta[main_index].data_meta.rows.push([]);
      this.loading = false;
    },
    editRowItem(row_index, main_index) {
      this.rowEditIndex = row_index;
      this.mainEditIndex = main_index;
      this.rowEdit = true;
    },
    updateRowItem(value) {
      this.loading = true;
      let temp = [...this.templateMeta];
      temp[this.mainEditIndex].data_meta.rows[this.rowEditIndex].push(value);
      this.rowEdit = false;
      this.rowEditIndex = null;
      this.mainEditIndex = null;
      this.templateMeta = temp;
      this.loading = false;
    },
    deleteRow(row_index, main_index) {
      this.templateMeta[main_index].data_meta.rows.splice(row_index, 1);
    },
    listDropdown(list_id) {
      let op = [];
      let lists = this.$store.getters.getListDataById(list_id);
      if (lists && lists.length > 0) {
        for (let i of lists) {
          op.push({ label: i.tag, value: i.value });
        }
      }
      return op;
    },
    toggleScan(item) {
      this.fieldData = null;
      if (item) {
        this.fieldData = item;
      }
      this.startScanning = !this.startScanning;
    },
    toggleImageComponent(item) {
      this.fieldData = null;
      if (item) {
        this.fieldData = item;
      }
      this.startImageUpload = !this.startImageUpload;
    },
    addQRData(value) {
      this.loading = true;
      if (value) {
        this.templateMeta[value.main_index].data_meta.rows[value.row_index][
          value.cell_index
        ].value.data = value.qrData;
      }
      this.fieldData = null;
      this.startScanning = false;
      this.loading = false;
    },
    addOCRData(value) {
      this.loading = true;
      if (value) {
        this.templateMeta[value.main_index].data_meta.rows[value.row_index][
          value.cell_index
        ].value.data = value.ocrData;
      }
      this.fieldData = null;
      this.startImageUpload = false;
      this.loading = false;
    },
    async solveHorizontalSingleCellEquation(row, equation) {
      try {
        if (!equation) return "";

        // Match column references (C1, C2, etc.)
        let matches = equation.match(/C\d+/g);

        if (matches) {
          matches.forEach((colRef) => {
            let colIndex = parseInt(colRef.substring(1)); // Extract number (e.g., C1 -> 1)
            let cellValue =
              parseInt(row[Number(colIndex - 1)]?.value.data) || 0;
            equation = equation.replace(colRef, cellValue);
          });
        }
        equation = equation.replace(/--/g, "+");
        // Evaluate the final expression safely
        // return evaluate(equation);
        if (!/^[0-9+\-*/().\s]+$/.test(equation)) {
          throw new Error("Invalid characters in equation");
        }

        // Safely evaluate using Function()
        return new Function(`return ${equation}`)();
      } catch (error) {
        console.error("Equation Error:", error);
        return "Error";
      }
    },
    async solveDateEquation(row, equation) {
      try {
        if (!equation) return "";

        // Match column references (C1, C2, etc.)
        let matches = equation.match(/C\d+/g);
        let values = {};

        if (matches) {
          matches.forEach((colRef) => {
            let colIndex = parseInt(colRef.substring(1)); // Extract column number
            let cellValue = row[Number(colIndex - 1)]?.value.data || "";

            // Determine if the value is a date or a number
            if (typeof cellValue === "number" || /^[0-9]+$/.test(cellValue)) {
              values[colRef] = parseFloat(cellValue); // Store as number
            } else if (cellValue.includes("-") && cellValue.includes(":")) {
              values[colRef] = moment(cellValue, "YYYY-MM-DD HH:mm"); // Date + Time
            } else if (cellValue.includes("-")) {
              values[colRef] = moment(cellValue, "YYYY-MM-DD"); // Only Date
            } else {
              values[colRef] = moment(
                moment().format("YYYY-MM-DD") + " " + cellValue,
                "YYYY-MM-DD HH:mm"
              ); // Only Time (Assume today’s date)
            }
          });
        }

        // Precompute time differences (convert moment objects to numeric values)
        Object.keys(values).forEach((colRef) => {
          if (moment.isMoment(values[colRef])) {
            values[colRef] = values[colRef].diff(moment(), "minutes"); // Convert to number
          }
        });

        // Replace column references in the equation with actual numeric values
        let processedEquation = equation.replace(
          /C\d+/g,
          (colRef) => values[colRef]
        );

        // Fix incorrect double negative signs (e.g., "0--2" → "0+2")
        processedEquation = processedEquation.replace(/--/g, "+");

        // Validate for safety
        if (!/^[0-9+\-*/().\s]+$/.test(processedEquation)) {
          throw new Error("Invalid characters in equation");
        }

        // Safely evaluate using Function()
        let result = new Function(`return ${processedEquation};`)();

        return result; // Final computed value
      } catch (error) {
        console.error("Equation Error:", error);
        return "Error";
      }
    },

    getDependentListByQueryKey(list_id, query_key) {
      if (list_id && query_key) {
        return this.$store.getters.getDependentListDataByIdAndKey(
          list_id,
          query_key
        );
      }
      return [];
    },
    getFormattedDate(date, format = "DD-MM-YYYY") {
      if (!date) {
        return moment().format(format);
      }
      return moment(date).format(format);
    },
    isFilledByPowerMaker() {
      let powerMakersUserids =
        this.$store.state.manufacturingLogbooksPowerMakersList.map(
          (x) => x.userid
        );
      return powerMakersUserids.includes(this.$store.state.user.userid);
    },
    createParamList() {
      if (this.copiedData && this.copiedData.length > 0) {
        this.loading = true;
        this.paramList = [];
        // let paramSet = new Set([]);
        let paramSet = [];
        for (let i of this.copiedData) {
          for (let row of i.data_meta.rows) {
            for (let cell of row) {
              if (cell.type === "auto" && cell.value.type === "param") {
                if (
                  !(
                    paramSet &&
                    paramSet.length > 0 &&
                    paramSet.some(
                      (obj) =>
                        obj.param_id === cell.value.template.param_id &&
                        obj.type === cell.value.template.param_type
                    )
                  )
                ) {
                  paramSet.push({
                    param_id: cell.value.template.param_id,
                    type: cell.value.template.param_type,
                  });
                }
                // paramSet.add(cell.value.template.param_id);
              }
            }
          }
        }
        this.paramList = [...paramSet];
        this.loading = false;
      }
    },
    getLogbookMetaData() {
      //   this.loading = true;
      this.templateMeta = [];
      this.copiedData = [];
      axios
        .post(
          this.$store.state.api + "getManufacturingLogbookDataById",
          {
            logbook_id: this.logbookData.logbook_id,
            timestamp: Number(this.logbookData.epoch_timestamp),
          },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            if (
              response.data.data &&
              response.data.data.length > 0 &&
              response.data.data[0].data &&
              response.data.data[0].data.rows &&
              response.data.data[0].data.rows.length > 0
            ) {
              let temp = [];
              for (let i of response.data.data) {
                temp.push({
                  logbook_id: i.logbook_id,
                  meta: i.meta,
                  organisation_id: i.organisation_id,
                  seq: i.seq,
                  table_key: i.table_key,
                  table_type: i.table_type,
                  timestamp: i.timestamp,
                  data_meta: i.data,
                });
                this.firstRow.push(i.data.rows[0]);
              }
              // this.templateMeta = [...temp];
              // this.copiedData = [...temp];
              this.templateMeta = JSON.parse(JSON.stringify(temp));
              this.copiedData = JSON.parse(JSON.stringify(temp));
            } else {
              this.getTemplateData();
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getTemplateData() {
      this.loading = true;
      this.templateMeta = [];
      this.copiedData = [];
      axios
        .post(
          this.$store.state.api + "manufacturingLogbookTemplateMetaById",
          { template_id: this.logbookData.template_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.templateMeta = JSON.parse(JSON.stringify(response.data.data));
            this.copiedData = JSON.parse(JSON.stringify(response.data.data));
            for (let i of response.data.data) {
              this.firstRow.push(i.data_meta.rows[0]);
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    replicateRow(main_index) {
      // replicateRow(main_index, replcate_from_row_index) {
      //   let temp = JSON.parse(
      //     JSON.stringify(
      //       this.templateMeta[main_index].data_meta.rows[replcate_from_row_index]
      //     )
      //   );
      let temp = JSON.parse(
        JSON.stringify(this.copiedData[main_index].data_meta.rows[0])
      );
      // let temp = [...this.firstRow[main_index]];
      //   console.log(temp);
      temp.forEach((item, index) => {
        if (item.type === "static") {
          temp[index] = {
            type: "input",
            value: {
              data: null,
              type: "text",
            },
          };
        } else if (item.type === "input" && item.value.type === "list_text") {
          temp[index] = {
            type: item.type,
            value: {
              data: null,
              type: item.value.type,
              meta: { list_id: item.value.meta.list_id },
            },
          };
        } else if (
          item.type === "input" &&
          item.value.type === "dependent_list_text"
        ) {
          temp[index] = {
            type: item.type,
            value: {
              data: null,
              type: item.value.type,
              meta: {
                list_id: item.value.meta.list_id,
                column_no: item.value.meta.column_no,
              },
            },
          };
        } else if (item.type === "auto") {
          let t = {
            data: null,
            type: item.value.type,
            template: item.value.template,
          };
          if (
            item.value.type === "date_delta" ||
            item.value.type === "time_delta"
          ) {
            t["meta"] = {
              delta: item.value.meta.delta ? item.value.meta.delta : 0,
            };
          }
          temp[index] = {
            type: item.type,
            value: t,
          };
        } else {
          temp[index] = {
            type: item.type,
            value: {
              data: null,
              type: item.value.type,
            },
          };
        }
      });
      this.templateMeta[main_index].data_meta.rows.push(temp);
    },
    getMakersList() {
      this.loading = true;
      this.checklistFormDetails = [];
      let op = [];
      axios
        .get(this.$store.state.api + "manufacturingLogbookMakersList", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            let dbData = [...response.data.data];
            for (let i of dbData) {
              op.push({ label: i.user_label, value: i.userid });
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
      this.userList = op;
    },
    moveStage(reject) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "moveManufacturingLogbookToNextStage",
          {
            reject,
            logbook_id: this.logbookData.logbook_id,
            timestamp: this.logbookData.epoch_timestamp,
            remarks: this.remarks,
          },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("closeForm");
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    refillSubmit() {
      if (this.$refs.refillForm.validate()) {
        this.refillRequest = false;
        this.loading = true;
        axios
          .post(
            this.$store.state.api + "requestForManufacturingLogbookRefill",
            {
              req_assignee: this.req_assignee,
              logbook_id: this.logbookData.logbook_id,
              timestamp: Number(this.logbookData.epoch_timestamp),
            },
            { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.$emit("refillChecklist");
              this.info = "Refill request raised successfully.";
              this.showDismissibleAlert = true;
              this.$emit("closeForm");
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    addCurrentTime(main_index, row_index, cell_index, template) {
      // console.log(this.dateTimeFormatMap[template]);
      this.loading = true;
      let current_time = moment().format(
        template ? this.dateTimeFormatMap[template] : "HH:mm"
      );
      // console.log(current_time, "current");
      this.templateMeta[main_index].data_meta.rows[row_index][
        cell_index
      ].value.data = current_time;
      this.loading = false;
    },
    async getDataFromReferenceSheet(type, template, main_index, row_index) {
      try {
        let key = null;
        if (type === "ref") {
          let cell = Number(template.key_col_no) - 1;
          key =
            this.templateMeta[main_index].data_meta.rows[row_index][cell].value
              .data;
        } else {
          let temp = template.key_col_no;
          let matches = temp.match(/C\d+/g);
          if (matches) {
            matches.forEach((colRef) => {
              let colIndex = parseInt(colRef.substring(1));
              let cellValue =
                this.templateMeta[main_index].data_meta.rows[row_index][
                  Number(colIndex - 1)
                ]?.value.data || "";
              temp = temp.replace(colRef, cellValue);
              key = temp;
            });
          }
        }
        let payload = {
          table_name: template.table_name,
          key_value: key,
        };
        let response = await axios.post(
          this.$store.state.api + "getManufacturingLogbookRefSheetDetails",
          payload,
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        );
        if (
          response.data.status === "success" &&
          response.data.data &&
          response.data.data.table_name &&
          response.data.data.meta &&
          Object.keys(response.data.data.meta).length > 0
        ) {
          return response.data.data.meta[template.meta_key];
        }
        this.loading = false;
        return null;
      } catch (error) {
        console.error(error);
      }
    },
    // getShiftName(fill_time, shifts) {
    //   console.log(fill_time);
    //   console.log(
    //     moment(fill_time, "HH:mm").isBetween(
    //       moment("16:00", "HH:mm"),
    //       moment("00:00", "HH:mm"),
    //       null,
    //       "[)"
    //     )
    //   );
    //   return (
    //     shifts.find((shift) =>
    //       moment(fill_time, "HH:mm").isBetween(
    //         moment(shift.from_time, "HH:mm"),
    //         moment(shift.to_time, "HH:mm"),
    //         null,
    //         "[)"
    //       )
    //     )?.name || fill_time
    //   );
    // },
    getShiftName(fill_time, shifts) {
      let fillMoment = moment(fill_time, "HH:mm");

      for (let shift of shifts) {
        let startMoment = moment(shift.from_time, "HH:mm");
        let endMoment = moment(shift.to_time, "HH:mm");

        // Handle shifts that go past midnight by adding 1 day to end time
        if (endMoment.isBefore(startMoment)) {
          endMoment.add(1, "day");
          if (fillMoment.isBefore(startMoment)) {
            fillMoment.add(1, "day"); // Ensure fill time aligns with overnight shifts
          }
        }

        // Check if fill_time is within the shift range
        if (fillMoment.isBetween(startMoment, endMoment, null, "[)")) {
          return shift.name;
        }
      }

      return fill_time; // If no matching shift found, return original time
    },

    handleAutoTimeGeneration(template, time, shift_id) {
      let default_time = this.fill_time.format(
        this.dateTimeFormatMap[template]
          ? this.dateTimeFormatMap[template]
          : "HH:mm"
      );
      if (template === "shift_name" && shift_id) {
        let fill_time = time
          ? time.format("HH:mm")
          : this.fill_time.format("HH:mm");
        let shiftDataDetails = this.shiftData;
        if (shiftDataDetails && shiftDataDetails.length > 0) {
          return this.getShiftName(fill_time, shiftDataDetails);
        }
        return default_time;
      }
      if (time) {
        return time.format(
          this.dateTimeFormatMap[template]
            ? this.dateTimeFormatMap[template]
            : "HH:mm"
        );
      }
      return default_time;
    },
    handleDateDelta(template, delta, shift_id) {
      let default_time = this.fill_time.format("HH:mm");
      if (template && template === "shift_name" && shift_id) {
        let time = moment().add(delta, "days").format("HH:mm");
        let shiftDataDetails = this.shiftData;
        if (shiftDataDetails && shiftDataDetails.length > 0) {
          return this.getShiftName(time, shiftDataDetails);
        }
      } else {
        const format = template
          ? this.dateTimeFormatMap[template]
          : "DD-MM-YYYY";
        return moment().add(delta, "days").format(format);
      }
      return default_time;
    },
    handleTimeDelta(template, delta, shift_id) {
      let default_time = this.fill_time.format("HH:mm");
      if (template && template === "shift_name" && shift_id) {
        let time = moment().add(delta, "minutes").format("HH:mm");
        let shiftDataDetails = this.shiftData;
        if (shiftDataDetails && shiftDataDetails.length > 0) {
          return this.getShiftName(time, shiftDataDetails);
        }
      } else {
        const format = template ? this.dateTimeFormatMap[template] : "HH:mm";
        return moment().add(delta, "days").format(format);
      }
      return default_time;
    },

    async autoGenerateFields(creation_time, shift_id) {
      let finalMeta = [];
      let save_time = moment();
      let temp = [...this.templateMeta];
      for (let [main_index, table] of temp.entries()) {
        finalMeta.push({
          meta: table.meta,
          organisation_id: table.organisation_id,
          seq: table.seq,
          table_key: table.table_key,
          table_type: table.table_type,
          template_id: table.template_id,
          data_meta: {
            headers: table.data_meta.headers,
            rows: [],
          },
        });
        for (let [row_index, row] of table.data_meta.rows.entries()) {
          let cells = [];
          for (let i of row) {
            if (
              i.type === "auto" &&
              i.value.data === null &&
              i.value.type !== "current_time"
            ) {
              let value = {
                type: i.value.type,
                template: i.value.template,
                data: null,
              };
              if (
                i.value.type === "date_delta" ||
                i.value.type === "time_delta"
              ) {
                value["meta"] = i.value.meta;
              }
              let o = {
                type: "auto",
                value,
              };
              let response = null;
              switch (i.value.type) {
                case "ref":
                  response = await this.getDataFromReferenceSheet(
                    i.value.type,
                    i.value.template,
                    main_index,
                    row_index
                  );
                  o.value.data = response;
                  break;
                case "multi_key_ref":
                  response = await this.getDataFromReferenceSheet(
                    i.value.type,
                    i.value.template,
                    main_index,
                    row_index
                  );
                  o.value.data = response;
                  break;
                case "horizontal_single_cell_equation":
                  response = await this.solveHorizontalSingleCellEquation(
                    cells,
                    i.value.template.equation
                  );
                  o.value.data = response;
                  break;
                case "date_time_equation":
                  response = await this.solveDateEquation(
                    cells,
                    i.value.template.equation
                  );
                  o.value.data = response;
                  break;
                case "fill_time":
                  response = this.handleAutoTimeGeneration(
                    i.value.template,
                    this.fill_time,
                    shift_id
                  );
                  o.value.data = response;
                  break;
                case "save_time":
                  response = this.handleAutoTimeGeneration(
                    i.value.template,
                    save_time,
                    shift_id
                  );
                  o.value.data = response;
                  break;
                case "creation_time":
                  response = this.handleAutoTimeGeneration(
                    i.value.template,
                    creation_time,
                    shift_id
                  );
                  o.value.data = response;
                  break;
                case "date_delta":
                  response = this.handleDateDelta(
                    i.value.template,
                    i.value.meta.delta,
                    shift_id
                  );
                  o.value.data = response;
                  break;
                case "time_delta":
                  response = this.handleDateDelta(
                    i.value.template,
                    i.value.meta.delta,
                    shift_id
                  );
                  o.value.data = response;
                  break;
              }
              cells.push(o);
            } else {
              cells.push(i);
            }
          }
          finalMeta[main_index].data_meta.rows.push(cells);
        }
      }
      return finalMeta && finalMeta.length > 0 ? finalMeta : this.templateMeta;
    },
    async submitForm() {
      try {
        this.loading = true;
        let creation_time = moment.unix(
          Number(this.logbookData.epoch_timestamp)
        );
        let finalData = await this.autoGenerateFields(
          creation_time,
          this.logbookData.shift_id
        );
        let table_name = "manufacturing_logbook_maker_list";
        let remove_lock = false;

        if (this.isFilledByPowerMaker()) {
          table_name = "manufacturing_logbook_power_maker_list";
          remove_lock = true;
        }
        let payload = {
          logbook_id: this.logbookData.logbook_id,
          timestamp: Number(this.logbookData.epoch_timestamp),
          submit: false,
          hasImage: false,
          manufacturing_logbook_meta_list: finalData,
        };
        payload["remove_lock"] = remove_lock;
        payload["table_name"] = table_name;
        let endpoint = "writeManufacturingLogbookData";
        if (
          this.logbookData.status === "refill" &&
          this.logbookData.stage === "maker"
        ) {
          endpoint = "refillManufacturingLogbookData";
        }
        let response = await axios.post(
          this.$store.state.api + endpoint,
          payload,
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        );
        this.loading = false;
        if (response.data.status === "success") {
          this.$emit("closeForm");
        } else {
          this.info = response.data.msg;
          this.showDismissibleAlert = true;
        }
      } catch (error) {
        console.error(error);
        this.info = error;
        this.showDismissibleAlert = true;
        this.loading = false;
      }
    },
  },
  watch: {
    logbookData: {
      handler() {
        this.init();
      },
      deep: true,
    },
    copiedData: {
      handler() {
        this.createParamList();
      },
      deep: true,
    },
    paramList: {
      handler() {
        this.streamInit();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
