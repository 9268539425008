<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-col>
        <v-card
          class="mb-1 pa-4"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
          outlined
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <v-data-table
            :headers="headers"
            :items="dbData"
            item-key="logbook_id"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            style="background: transparent"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().log_book
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$store.getters.getTextMap().search"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().log_book
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="cancelLogbook" :colspan="headers.length">
                <v-row class="mt-1">
                  <v-col>
                    <h2
                      :style="{
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().cancel }}
                      {{ $store.getters.getTextMap().log_book }}
                    </h2>
                  </v-col>
                </v-row>
                <div
                  v-if="cancelLogbookDetails && cancelLogbookDetails.logbook_id"
                >
                  <v-card
                    class="my-5"
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="
                      $store.getters.getColorPalette().background2ColorCode
                    "
                    style="border-radius: 12px"
                  >
                    <div
                      :style="{
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }"
                    >
                      <div>
                        {{ cancelLabelTypeMap[cancelLogbookDetails.req_type] }}
                        {{ $store.getters.getTextMap().is_be_cancelled }}
                      </div>
                      <div>
                        <DeleteConfirmation
                          v-on:confirm="deleteCancelledLogbookEvent(item)"
                          title="Delete Confirmation"
                          description="Are you sure you want to delete this Event?"
                        >
                          <v-btn
                            class="mx-1"
                            :color="$store.getters.getColorPalette().accentCode"
                            small
                            text
                            outlined
                          >
                            {{ $store.getters.getTextMap().delete_this }}
                            {{ $store.getters.getTextMap().event }}

                            <v-icon
                              right
                              :isDark="$store.getters.getColorPalette().isDark"
                              :color="
                                $store.getters.getColorPalette().deletebtnColor
                              "
                              small
                              >mdi-trash-can</v-icon
                            >
                          </v-btn>
                        </DeleteConfirmation>
                      </div>
                    </div>
                  </v-card>
                  <v-row no-gutters class="mb-5">
                    <v-col align="right">
                      <v-skeleton-loader
                        v-if="loading"
                        type="text"
                      ></v-skeleton-loader>
                      <v-btn
                        class="mx-1"
                        v-if="!loading"
                        :color="$store.getters.getColorPalette().cancelbtnColor"
                        small
                        @click="cancelLogbook = false"
                        >{{ $store.getters.getTextMap().cancel }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row class="mt-5">
                    <v-col>
                      <v-form ref="logbookCancelForm" v-model="isFormValid">
                        <v-select
                          v-if="!loading"
                          v-model="req_type"
                          :items="cancelTypeOption"
                          :label="$store.getters.getTextMap().type"
                          dense
                          outlined
                          item-text="label"
                          item-value="value"
                          :menu-props="{
                            dark: $store.getters.getColorPalette().isDark,
                          }"
                          :rules="[rules.required]"
                          :dark="$store.getters.getColorPalette().isDark"
                        ></v-select>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mb-5">
                    <v-col align="right">
                      <v-skeleton-loader
                        v-if="loading"
                        type="text"
                      ></v-skeleton-loader>
                      <v-btn
                        class="mx-1"
                        v-if="!loading"
                        :color="$store.getters.getColorPalette().cancelbtnColor"
                        small
                        @click="cancelLogbook = false"
                        >{{ $store.getters.getTextMap().cancel }}</v-btn
                      >
                      <v-btn
                        v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                        small
                        @click="logbookCancelSubmit(item)"
                        >{{ $store.getters.getTextMap().submit }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </td>
              <td v-else :colspan="headers.length">
                <v-btn
                  :color="$store.getters.getColorPalette().accentCode"
                  small
                  text
                  outlined
                  v-on:click="editLogbook(item)"
                  >{{ $store.getters.getTextMap().edit }}

                  <v-icon
                    right
                    :isDark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().deletebtnColor"
                    small
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn
                  class="mx-1"
                  :color="$store.getters.getColorPalette().accentCode"
                  small
                  text
                  outlined
                  v-on:click="getCancelLogbookDetails(item)"
                  >{{ $store.getters.getTextMap().cancel }}
                  {{ $store.getters.getTextMap().log_book }}

                  <v-icon
                    right
                    :isDark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().deletebtnColor"
                    small
                    >mdi-cancel</v-icon
                  >
                </v-btn>
                <DeleteConfirmation
                  v-on:confirm="deleteLogbook(item)"
                  title="Delete Confirmation"
                  description="Are you sure you want to delete this Log?"
                >
                  <v-btn
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    outlined
                  >
                    {{ $store.getters.getTextMap().delete }}

                    <v-icon
                      right
                      :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor"
                      small
                      >mdi-trash-can</v-icon
                    >
                  </v-btn>
                </DeleteConfirmation>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import axios from "axios";
import FormRules from "@/utillities/FormRules";
export default {
  name: "ManufacturingLogbookList",
  props: ["refetchData"],
  components: {
    InfoAlert,
    DeleteConfirmation,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      isFormValid: false,
      expanded: [],
      singleExpand: false,
      sortDesc: false,
      search: "",
      sortBy: "created",
      dbData: [],
      cancelLogbook: false,
      cancelLogbookDetails: {},
      rules: FormRules.rules,
      req_type: "next",
      cancelTypeOption: [
        { label: "Next Logbook In Queue", value: "next" },
        { label: "All Logbooks in the Series", value: "series" },
      ],
      cancelLabelTypeMap: {
        next: "Next Logbook In Queue",
        series: "All Logbooks in the Series",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Maker",
          value: "maker_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Power Maker",
          value: "power_maker_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Checker",
          value: "checker_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Approver",
          value: "approver_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Issuer",
          value: "issuer_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "ID",
          value: "logbook_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    userGroups() {
      let op = [];
      op = this.$store.getters.getUserGroups();
      return op;
    },
  },
  methods: {
    getData() {
      this.dbData = [];
      this.loading = true;
      axios
        .get(this.$store.state.api + "manufacturing_logbooks", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.data.length > 0) {
              response.data.data.map((item) => {
                item["maker_group_name"] = this.userGroups.find((k) => {
                  return item.maker === k.group_id;
                })?.name;
                item["power_maker_group_name"] = this.userGroups.find((k) => {
                  return item.power_maker === k.group_id;
                })?.name;
                item["approver_group_name"] = this.userGroups.find((k) => {
                  return item.approver === k.group_id;
                })?.name;
                item["checker_group_name"] = this.userGroups.find((k) => {
                  return item.checker === k.group_id;
                })?.name;
                item["issuer_group_name"] = this.userGroups.find((k) => {
                  return item.issuer === k.group_id;
                })?.name;
              });
              this.dbData = response.data.data;
            }
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.info = err;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    editLogbook(d) {
      this.$emit("editLogbook", d);
    },
    deleteLogbook(d) {
      axios
        .post(
          this.$store.state.api + "deleteManufacturingLogbook",
          { logbook_id: d.logbook_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.info = "Logbook  Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
            this.getData();
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getCancelLogbookDetails(d) {
      this.cancelLogbook = true;
      axios
        .post(
          this.$store.state.api + "getCancelledManufacturingLogbookById",
          { logbook_id: d.logbook_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.cancelLogbookDetails = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    logbookCancelSubmit(d) {
      if (this.$refs.logbookCancelForm.validate()) {
        this.loading = true;
        let payload = {
          req_type: this.req_type,
          logbook_id: d.logbook_id,
        };
        axios
          .post(this.$store.state.api + "cancelManufacturingLogbook", payload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.getCancelLogbookDetails(d);
              this.info = "Logbook  Cancelled";
              this.showDismissibleAlert = true;
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    deleteCancelledLogbookEvent(d) {
      axios
        .post(
          this.$store.state.api + "deleteCancelledManufacturingLogbookEvent",
          { logbook_id: d.logbook_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getCancelLogbookDetails(d);
            this.info = "Event  Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
  },
  watch: {
    refetchData: {
      handler() {
        if (this.refetchData) {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
