<template>
  <v-dialog v-model="show" max-width="500">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-form ref="groupForm" v-model="valid">
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
      >
        <v-card-text>
          <!-- Type Selection -->
          <v-select
            v-model="type"
            :items="typeOptions"
            label="Type"
            outlined
            dense
            item-text="label"
            item-value="value"
            required
            :rules="objectRules"
          ></v-select>
          <v-select
            v-if="type === 'input'"
            placeholder="Field Type"
            v-model="field_type"
            :rules="objectRules"
            :items="fieldTypeOptions"
            item-text="label"
            item-value="value"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else-if="type === 'static'"
            v-model="text"
            :counter="500"
            :rules="nameRules"
            dense
            outlined
            :label="$store.getters.getTextMap().value"
            required
          ></v-text-field>
          <v-select
            v-if="field_type === 'list_text' && type === 'input'"
            placeholder="Select List"
            v-model="list_id"
            :items="lists"
            item-text="label"
            item-value="value"
            outlined
            dense
          >
          </v-select>
          <v-select
            v-if="field_type === 'dependent_list_text' && type === 'input'"
            placeholder="Select List"
            v-model="list_id"
            :items="dependentLists"
            item-text="label"
            item-value="value"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-if="field_type === 'dependent_list_text' && type === 'input'"
            v-model="reference_col_for_dependent_list"
            :counter="500"
            :rules="nameRules"
            dense
            outlined
            :label="
              $store.getters.getTextMap().reference +
              ' ' +
              $store.getters.getTextMap().column
            "
            required
          ></v-text-field>

          <!-- Auto Field Type Selection (Visible if type === "auto") -->
          <v-select
            v-if="type === 'auto'"
            v-model="autoType"
            :items="autoFieldTypeOptions"
            label="Auto Generate Type"
            outlined
            dense
            item-text="label"
            item-value="value"
            required
            :rules="objectRules"
          ></v-select>
          <v-text-field
            v-if="
              autoType === 'horizontal_single_cell_equation' && type === 'auto'
            "
            v-model="horizontalSingleCellEquation"
            :counter="500"
            :rules="nameRules"
            dense
            outlined
            :label="$store.getters.getTextMap().add_equation"
            required
          ></v-text-field>
          <v-text-field
            v-if="autoType === 'date_time_equation' && type === 'auto'"
            v-model="dateTimeEquation"
            :counter="500"
            :rules="nameRules"
            dense
            outlined
            :label="$store.getters.getTextMap().add_equation"
            required
          ></v-text-field>
          <!-- Fill Time Template Selection -->
          <div v-if="type === 'auto' && autoType === 'param'">
            <WorkspaceMachineParamForm
              :multiple="false"
              v-on:value="updateParamList"
            />
            <v-select
              v-if="!loading"
              v-model="parameter_type"
              :items="paramTypeOptions"
              label="Param Type"
              outlined
              dense
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            ></v-select>
          </div>
          <v-select
            v-if="
              type === 'auto' &&
              [
                'fill_time',
                'save_time',
                'creation_time',
                'current_time',
                'date_delta',
                'time_delta',
              ].includes(autoType)
            "
            v-model="fillTimeTemplate"
            :items="fillTimeTemplateOptions"
            label="Select Time Format"
            outlined
            dense
            item-text="label"
            item-value="value"
            required
            :rules="objectRules"
          ></v-select>
          <v-text-field
            v-if="autoType === 'date_delta' || autoType === 'time_delta'"
            v-model="delta"
            :counter="500"
            :rules="numberRules"
            outlined
            dense
            :label="$store.getters.getTextMap().value"
            required
          ></v-text-field>
          <!-- Reference Sheet Inputs -->
          <div v-if="type === 'auto' && autoType === 'ref'">
            <!-- <v-text-field
              v-model="refTemplate.table_name"
              label="Reference Table Name"
              outlined
              dense
            ></v-text-field> -->
            <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
            <v-select
              v-if="type === 'auto' && !loading"
              v-model="refTemplate.table_name"
              :items="referenceTableOptions"
              label="Select Reference Table"
              outlined
              dense
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            ></v-select>
            <v-text-field
              v-model="refTemplate.key_col_no"
              label="Column number of the key column"
              outlined
              dense
              type="number"
            ></v-text-field>
            <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
            <v-select
              v-if="!loading"
              v-model="refTemplate.meta_key"
              :items="columnTableOptions"
              label="Column name in the reference table"
              outlined
              dense
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            ></v-select>
            <!-- <v-text-field
              v-model="refTemplate.meta_key"
              label="Column name in the reference table"
              outlined
              dense
            ></v-text-field> -->
          </div>
          <div v-if="type === 'auto' && autoType === 'multi_key_ref'">
            <!-- <v-text-field
              v-model="refTemplate.table_name"
              label="Reference Table Name"
              outlined
              dense
            ></v-text-field> -->
            <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
            <v-select
              v-if="type === 'auto' && !loading"
              v-model="multiKeyRefTemplate.table_name"
              :items="referenceTableOptions"
              label="Select Reference Table"
              outlined
              dense
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            ></v-select>
            <v-text-field
              v-model="multiKeyRefTemplate.key_col_no"
              label="Column number of the key column"
              outlined
              dense
              type="text"
            ></v-text-field>
            <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
            <v-select
              v-if="!loading"
              v-model="multiKeyRefTemplate.meta_key"
              :items="columnTableOptions"
              label="Column name in the reference table"
              outlined
              dense
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            ></v-select>
            <!-- <v-text-field
              v-model="refTemplate.meta_key"
              label="Column name in the reference table"
              outlined
              dense
            ></v-text-field> -->
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert.vue";
import WorkspaceMachineParamForm from "@/components/forms/WorkspaceMachineParamForm.vue";
export default {
  name: "GetManufacturingLogbookFieldModal",
  props: ["show", "name"],
  components: {
    InfoAlert,
    WorkspaceMachineParamForm,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      valid: false,
      type: "static",
      text: null,
      field_type: null,
      list_id: null,
      delta: 0,
      autoType: null,
      fillTimeTemplate: null,
      reference_col_for_dependent_list: null,
      refTemplate: {
        table_name: "",
        key_col_no: null,
        meta_key: "",
      },
      multiKeyRefTemplate: {
        table_name: "",
        key_col_no: null,
        meta_key: "",
      },
      referenceTables: [],
      referenceTableOptions: [],
      columnTableOptions: [],
      horizontalSingleCellEquation: null,
      dateTimeEquation: null,
      parameter: null,
      parameter_label: null,
      parameter_type: null,
      // referenceTables:[],
      // referenceColumnFromReferenceTableOptions: [],
      typeOptions: [
        { label: "Static", value: "static" },
        { label: "Input", value: "input" },
        { label: "Auto Generate", value: "auto" },
      ],
      paramTypeOptions: [
        { label: "Real Time Data", value: "rtd" },
        { label: "Real Time Consumption", value: "rtc" },
      ],
      autoFieldTypeOptions: [
        { label: "Reference From Sheet", value: "ref" },
        {
          label: "Reference From Sheet with multiple keys",
          value: "multi_key_ref",
        },
        {
          label: "Horizontal Single Cell Equation",
          value: "horizontal_single_cell_equation",
        },
        {
          label: "Date Time Equation",
          value: "date_time_equation",
        },
        { label: "Parameter", value: "param" },
        { label: "Fill Time", value: "fill_time" },
        { label: "Submission Time", value: "save_time" },
        { label: "Creation Time", value: "creation_time" },
        { label: "Current Time", value: "current_time" },
        { label: "Date Delta", value: "date_delta" },
        { label: "Time Delta", value: "time_delta" },
      ],
      fillTimeTemplateOptions: [
        { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
        { label: "DD-MM-YYYY HH:mm", value: "DD-MM-YYYY HH:mm" },
        { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
        { label: "YYYY-MM-DD HH:mm", value: "YYYY-MM-DD HH:mm" },
        { label: "HH:mm", value: "HH:mm" },
        { label: "MM-YYYY", value: "MM-YYYY" },
        { label: "DD-MM", value: "DD-MM" },
        { label: "Shift Name", value: "shift_name" },
      ],
      fieldTypeOptions: [
        {
          label: "Text",
          value: "text",
        },
        {
          label: "Text from Dropdown",
          value: "list_text",
        },
        {
          label: "Dependent List",
          value: "dependent_list_text",
        },
        {
          label: "Check",
          value: "check",
        },
        {
          label: "Calendar",
          value: "calendar",
        },
        {
          label: "Clock",
          value: "clock",
        },
        // {
        //     label: "Image",
        //     value: 'image'
        // },
        {
          label: "QR Code",
          value: "qrtext",
        },
        {
          label: "Image to Text Conversion",
          value: "ocr",
        },
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v <= 365) || "Name must be less than 365",
      ],
      nameRules: [
        (v) => !!v || "required",
        (v) => /\S+/.test(v) || "Text only",
        (v) =>
          (v && v.length <= 500 && v.length > 0) ||
          "Name must be less than 501 characters",
      ],
      objectRules: [(v) => !!v || "Required"],
    };
  },
  mounted() {
    if (this.name) {
      this.text = this.name;
    }
  },
  computed: {
    filteredFillTimeOptions() {
      return this.autoType === "fill_time"
        ? this.fillTimeTemplateOptions
        : this.fillTimeTemplateOptions.filter(
            (opt) => opt.value !== "shift_name"
          );
    },
    lists() {
      let op = [];
      for (let i of this.$store.state.lists) {
        op.push({ label: i.name, value: i.list_id });
      }
      return op;
    },
    dependentLists() {
      let op = [];
      for (let i of this.$store.state.dependentLists) {
        op.push({ label: i.name, value: i.list_id });
      }
      return op;
    },
  },
  methods: {
    updateParamList(value) {
      if (value && value.parameter && value.parameter.param_id) {
        this.parameter = value.parameter.param_id;
        this.parameter_label = value.parameter.name;
      }
    },
    getReferenceTables() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "manufacturingLogbookRefSheets", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            for (let i of response.data.data) {
              this.referenceTables = [...response.data.data];
              this.referenceTableOptions.push({
                label: i.table_name,
                value: i.table_name,
              });
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    createColumnOptions() {
      this.loading = true;
      let sheet = null;
      if (this.autoType === "ref") {
        sheet = this.referenceTables.find(
          (x) => x.table_name === this.refTemplate.table_name
        );
      } else {
        sheet = this.referenceTables.find(
          (x) => x.table_name === this.multiKeyRefTemplate.table_name
        );
      }
      let op = [];
      for (let i of Object.keys(sheet.meta)) {
        op.push({ label: i, value: i });
      }
      this.columnTableOptions = [...op];
      this.loading = false;
    },
    onSubmit() {
      this.$refs.groupForm.validate();
      if (this.valid) {
        let returnValue = { type: this.type, value: {} };
        if (this.type === "static") {
          returnValue.value = this.text;
        } else if (this.type === "input") {
          returnValue.value.type = this.field_type;
          if (this.field_type === "check") {
            returnValue.value.data = false;
          } else if (this.field_type === "list_text") {
            returnValue.value.data = null;
            returnValue.value.meta = { list_id: this.list_id };
            this.list_id = null;
          } else if (this.field_type === "dependent_list_text") {
            returnValue.value.data = null;
            returnValue.value.meta = {
              list_id: this.list_id,
              column_no: this.reference_col_for_dependent_list,
            };
            this.list_id = null;
            this.reference_col_for_dependent_list = null;
          } else {
            returnValue.value.data = null;
          }
        } else {
          returnValue.value.data = null;
          returnValue.value.type = this.autoType;
          if (
            this.autoType === "fill_time" ||
            this.autoType === "save_time" ||
            this.autoType === "creation_time" ||
            this.autoType === "current_time" ||
            this.autoType === "date_delta" ||
            this.autoType === "time_delta"
          ) {
            returnValue.value.template = this.fillTimeTemplate;
            if (
              this.autoType === "date_delta" ||
              this.autoType === "time_delta"
            ) {
              returnValue.value.meta = { delta: this.delta };
              this.delta = 0;
            }
          }
          if (this.autoType === "ref") {
            returnValue.value.template = {};
            returnValue.value.template = {
              table_name: this.refTemplate.table_name,
              key_col_no: this.refTemplate.key_col_no,
              meta_key: this.refTemplate.meta_key,
            };
          }
          if (this.autoType === "param") {
            returnValue.value.template = {};
            returnValue.value.template = {
              param_id: this.parameter,
              param_label: this.parameter_label,
              param_type: this.parameter_type,
            };
            this.parameter = null;
            this.parameter_label = null;
          }
          if (this.autoType === "multi_key_ref") {
            returnValue.value.template = {};
            returnValue.value.template = {
              table_name: this.multiKeyRefTemplate.table_name,
              key_col_no: this.multiKeyRefTemplate.key_col_no,
              meta_key: this.multiKeyRefTemplate.meta_key,
            };
          }
          if (this.autoType === "horizontal_single_cell_equation") {
            returnValue.value.data = null;
            returnValue.value.template = {
              equation: this.horizontalSingleCellEquation,
            };
            this.horizontalSingleCellEquation = null;
          }
          if (this.autoType === "date_time_equation") {
            returnValue.value.data = null;
            returnValue.value.template = {
              equation: this.dateTimeEquation,
            };
            this.dateTimeEquation = null;
          }
        }

        this.$emit("value", returnValue);
        this.$emit("close");
      }
    },
    onReset() {
      this.$emit("close");
    },
  },
  watch: {
    autoType: {
      handler() {
        if (this.autoType === "ref" || this.autoType === "multi_key_ref") {
          this.getReferenceTables();
        }
      },
      deep: true,
    },
    refTemplate: {
      handler() {
        if (this.refTemplate.table_name) {
          this.createColumnOptions();
        }
      },
      deep: true,
    },
    multiKeyRefTemplate: {
      handler() {
        if (this.multiKeyRefTemplate.table_name) {
          this.createColumnOptions();
        }
      },
      deep: true,
    },
  },
};
</script>
