var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding",attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('v-row',[_c('v-col',{attrs:{"align":"center","align-self":"center"}},[_c('v-menu',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"label":_vm.$store.getters.getTextMap().from_date,"prepend-icon":"mdi-calendar","readonly":"","outlined":"","clearable":"","loading":_vm.loading,"rules":_vm.ISODateRules,"dense":""},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"cyan lighten-1"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1)],1),_c('v-col',{attrs:{"align":"center","align-self":"center"}},[_c('v-menu',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"cyan lighten-1","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"cyan lighten-1","label":_vm.$store.getters.getTextMap().to_date,"prepend-icon":"mdi-calendar","readonly":"","dense":"","clearable":"","outlined":"","loading":_vm.loading,"rules":_vm.ISODateRules},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"cyan lighten-1"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1)],1),_c('v-col',[(!_vm.loading)?_c('v-select',{attrs:{"items":_vm.timestampTypeOptions,"label":_vm.$store.getters.getTextMap().timestamp +
          ' ' +
          _vm.$store.getters.getTextMap().type,"dense":"","outlined":"","item-text":"label","item-value":"value","menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.timestamp_type),callback:function ($$v) {_vm.timestamp_type=$$v},expression:"timestamp_type"}}):_vm._e()],1),_c('v-col',[(!_vm.loading)?_c('v-select',{attrs:{"items":_vm.statusOptions,"label":_vm.$store.getters.getTextMap().status,"dense":"","outlined":"","small":"","item-text":"label","item-value":"value","menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_vm._e()],1),_c('v-col',[(!_vm.loading)?_c('v-select',{attrs:{"items":_vm.stageOptions,"label":_vm.$store.getters.getTextMap().stage,"dense":"","outlined":"","item-text":"label","item-value":"value","menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}}):_vm._e()],1),_c('v-col',{attrs:{"align-items":"center"}},[(!_vm.loading)?_c('v-btn',{attrs:{"outlined":"","text":"","small":"","dark":_vm.$store.getters.getColorPalette().isDark},on:{"click":_vm.getData}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().submit))]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mb-1 pa-4",style:({
          border:
            '1px solid' +
            _vm.$store.getters.getColorPalette().panelBorderColorCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode,"outlined":""}},[_c('v-data-table',{staticStyle:{"background":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.logbooks,"item-key":"index","search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().log_book))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{style:(_vm.getStatusStyle(item.status))},[_vm._v(" "+_vm._s(_vm.statusNameMap[item.status])+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"small":"","outlined":"","text":""},on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v(" View Details ")])],1)]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{style:({
          border: '2px solid ' + _vm.$store.getters.getColorPalette().accentCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"hide-overlay":"","fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ManufacturingLogbookMakerForm',{attrs:{"logbookData":_vm.logbookData,"readOnly":true},on:{"closeForm":_vm.closeDialog}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }