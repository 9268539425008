<template>
  <v-container
    fluid
    :style="{
      'background-color': $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <!-- config checklist button -->
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
    >
      <div :style="{ marginBottom: '5px' }">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </div>
      <div>
        <v-btn
          :color="$store.getters.getColorPalette().btnColor"
          outlined
          text
          elevation="0"
          small
          @click="openDialog"
        >
          {{ $store.getters.getTextMap().create_checklist_template }}
        </v-btn>
        <v-btn
          class="ml-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
          small
          text
          outlined
          @click="getConfiguredChecklistData"
        >
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog
      :dark="$store.getters.getColorPalette().isDark"
      v-model="dialog"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
        flat
      >
        <!-- config checklist form -->
        <v-form ref="form" v-model="valid">
          <v-card
            class="px-10 py-5"
            elevation="0"
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().backgroundColorCode"
            flat
          >
            <div class="d-flex justify-space-between">
              <h3 class="py-4">
                {{ $store.getters.getTextMap().create_checklist_template }}
              </h3>
              <div>
                <v-btn
                  :color="$store.getters.getColorPalette().submitbtnColor"
                  @click="closeForm"
                  class="mr-3"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  placeholder="Checklist Name"
                  v-model="name"
                  :rules="[rules.required]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-checkbox
                  v-if="!loading"
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-model="table_second"
                  :label="$store.getters.getTextMap().table_second"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  placeholder="Checklist Description"
                  v-model="msg"
                  outlined
                  dense
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  :label="$store.getters.getTextMap().document_no"
                  placeholder="Document Number"
                  v-model="doc_no"
                  outlined
                  dense
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  :label="$store.getters.getTextMap().version_no"
                  placeholder="Version Number"
                  v-model="version_no"
                  outlined
                  dense
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-menu
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                      v-model="version_date"
                      :label="$store.getters.getTextMap().version_date"
                      outlined
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="version_date"
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().inputtextColor"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  :label="$store.getters.getTextMap().issue_no"
                  placeholder="Issue No"
                  v-model="issue_no"
                  outlined
                  dense
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-menu
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                      v-model="issue_date"
                      :label="$store.getters.getTextMap().issue_date"
                      outlined
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="issue_date"
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().inputtextColor"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ChecklistTableForm
                  :table_json="table_json"
                  v-on:value="createTableJson"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="5">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-text-field
                  v-if="!loading"
                  placeholder="Enter Field Name"
                  v-model="field_name"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-select
                  v-if="!loading"
                  placeholder="Enter Field Type"
                  v-model="field_type"
                  :items="fieldTypeOptions"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col v-if="field_type === 'list_text'">
                <v-select
                  v-if="!loading"
                  placeholder="Select List"
                  v-model="list_id"
                  :items="lists"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-btn
                  v-if="!loading"
                  :color="$store.getters.getColorPalette().submitbtnColor"
                  @click="addToList()"
                  >Add</v-btn
                >
              </v-col>
              <span class="mt-n10 px-5">
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
                <v-checkbox
                  v-if="!loading"
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-model="type_rule"
                  :label="$store.getters.getTextMap().type_rule"
                ></v-checkbox>
              </span>
            </v-row>
          </v-card>
        </v-form>

        <!-- meta data table -->
        <v-row v-if="!loading">
          <v-col cols="12" v-if="tableData.length > 0" class="px-10">
            <v-simple-table
              :color="$store.getters.getColorPalette().backgroundColorName"
              fixed-header
              dense
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                'background-color':
                  $store.getters.getColorPalette().backgroundColorCode,
                color: $store.getters.getColorPalette().accentCode,
                border: `1px solid ${
                  $store.getters.getColorPalette().accentCode
                }`,
              }"
            >
              <template v-slot:default>
                <thead
                  :style="{
                    'background-color':
                      $store.getters.getColorPalette().backgroundColorCode,
                    color: $store.getters.getColorPalette().accentCode,
                  }"
                >
                  <tr>
                    <th
                      :class="' text-left  text-subtitle-1 text-capitalize '"
                      :style="{
                        'background-color':
                          $store.getters.getColorPalette().foregroundColorCode,
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().field_name }}
                    </th>
                    <th
                      :class="' text-left  text-subtitle-1 text-capitalize '"
                      :style="{
                        'background-color':
                          $store.getters.getColorPalette().foregroundColorCode,
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().field_type }}
                    </th>
                    <th
                      :class="' text-left  text-subtitle-1 text-capitalize '"
                      :style="{
                        'background-color':
                          $store.getters.getColorPalette().foregroundColorCode,
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().type_rule }}
                    </th>
                    <th
                      :class="' text-left  text-subtitle-1 text-capitalize '"
                      :style="{
                        'background-color':
                          $store.getters.getColorPalette().foregroundColorCode,
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().arrange }}
                    </th>
                    <th
                      :class="' text-left  text-subtitle-1 text-capitalize '"
                      :style="{
                        'background-color':
                          $store.getters.getColorPalette().foregroundColorCode,
                        color: $store.getters.getColorPalette().accentCode,
                      }"
                    >
                      {{ $store.getters.getTextMap().delete }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  :style="{
                    'background-color':
                      $store.getters.getColorPalette().backgroundColorCode,
                  }"
                >
                  <tr
                    v-for="(d, ind) in tableData"
                    :key="ind"
                    :style="{
                      'background-color':
                        $store.getters.getColorPalette().backgroundColorCode,
                      color: $store.getters.getColorPalette().accentCode,
                    }"
                  >
                    <td class="text-lef">{{ d.key_text }}</td>
                    <td class="text-left">{{ d.entry_type }}</td>
                    <td class="text-left">{{ d.required }}</td>
                    <td>
                      <!-- <div style="display: flex; justify-content: space-between;"> -->
                      <v-icon
                        @click="moveUp(ind)"
                        :disabled="ind === 0"
                        color="blue"
                        >mdi-arrow-up-thin</v-icon
                      >
                      <v-icon
                        @click="moveDown(ind)"
                        :disabled="ind === tableData.length - 1"
                        color="blue"
                        >mdi-arrow-down-thin</v-icon
                      >
                      <!-- </div> -->
                    </td>
                    <td class="text-left">
                      <DeleteConfirmation
                        v-on:confirm="deleteFromTableData(d)"
                        title="Delete Confirmation"
                        description="Are you sure you want to delete this gauge?"
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pa-8">
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="onSubmit"
              class="mr-3"
            >
              {{ $store.getters.getTextMap().submit }}
            </v-btn>
            <v-btn
              :color="$store.getters.getColorPalette().cancelbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              @click="closeForm"
              class="ml-3"
            >
              {{ $store.getters.getTextMap().cancel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- List of added checklist Templates -->
    <v-row>
      <v-col cols="12">
        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="templates"
          item-key="template_id"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
          loading-text="Loading... Please wait"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{
                $store.getters.getTextMap().checklist_templates
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-btn
                class="mx-1"
                :color="$store.getters.getColorPalette().accentCode"
                small
                text
                outlined
                v-on:click="editTemplate(item)"
                >{{ $store.getters.getTextMap().edit_checklist_template }}

                <v-icon
                  right
                  :isDark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().deletebtnColor"
                  small
                  >mdi-pencil</v-icon
                >
              </v-btn>

              <DeleteConfirmation
                v-on:confirm="deleteChecklistTemplate(item)"
                title="Delete Confirmation"
                description="Are you sure you want to delete this Log?"
              >
                <v-btn
                  class="mx-1"
                  :color="$store.getters.getColorPalette().accentCode"
                  small
                  text
                  outlined
                >
                  {{ $store.getters.getTextMap().delete_checklist_template }}

                  <v-icon
                    right
                    :isDark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().deletebtnColor"
                    small
                    >mdi-trash-can</v-icon
                  >
                </v-btn>
              </DeleteConfirmation>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteConfirmation from "@/components/DeleteConfirmation";
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
import moment from "moment";
import ChecklistTableForm from "@/components/forms/checklists/TableForms/ChecklistTableForm.vue";
// import createChecklistTemplateDialog from './createChecklistTemplateDialog.vue';

export default {
  name: "ConfigChecklist",
  components: {
    DeleteConfirmation,
    InfoAlert,
    ChecklistTableForm,
    // createChecklistTemplateDialog
  },
  mounted() {
    this.getConfiguredChecklistData();
  },

  data() {
    return {
      rules: FormRules.rules,
      showDismissibleAlert: false,
      info: "",
      search: "",
      loading: false,
      menu: null,
      menu1: null,
      sortDesc: false,
      sortBy: "created",
      expanded: [],
      singleExpand: false,
      dialog: false,
      openForm: false,
      name: null,
      msg: null,
      doc_no: null,
      version_no: null,
      version_date: null,
      issue_no: null,
      issue_date: null,
      table_second: false,
      field_name: null,
      field_type: null,
      type_rule: false,
      selectedTemplate: null,
      endpoint: "createChecklistTemplate",
      meta: [],
      list_id: null,
      tableData: [],
      templates: [],
      table_json: {},
      fieldTypeOptions: [
        {
          label: "Text",
          value: "text",
        },
        {
          label: "Text from Dropdown",
          value: "list_text",
        },
        {
          label: "Image",
          value: "image",
        },
        {
          label: "Check",
          value: "check",
        },
        {
          label: "Calendar",
          value: "calendar",
        },
        {
          label: "Clock",
          value: "clock",
        },
        {
          label: "QR Code",
          value: "qrtext",
        },
        {
          label: "Image to Text Conversion",
          value: "ocr",
        },
      ],
      valid: false,
      edit: false,
      required: (value) => value !== " " && value !== undefined,
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Document Number",
          value: "doc_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Version Number",
          value: "version_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Version Date",
          value: "version_date",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Issue Number",
          value: "issue_no",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "issue Date",
          value: "issue_date",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Description",
          value: "msg",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Created",
          value: "created",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  computed: {
    lists() {
      let op = [];
      for (let i of this.$store.state.lists) {
        op.push({ label: i.name, value: i.list_id });
      }
      return op;
    },
  },
  methods: {
    onReset() {
      this.$refs.form.reset();
    },
    createTableJson(value) {
      this.table_json = value;
      this.info = "Added Successfully!";
      this.showDismissibleAlert = true;
    },
    onSubmit() {
      // create submit
      if (!this.edit) {
        this.$refs.form.validate();
        if (this.valid) {
          let payload = {
            name: this.name,
            msg: this.msg,
            doc_no: this.doc_no,
            checklist_meta: this.meta,
            table_second: this.table_second,
            version_no: this.version_no,
            version_date: this.version_date,
            issue_no: this.issue_no,
            issue_date: this.issue_date,
            update_template: false,
          };
          this.loading = true;
          // console.log(payload, "payload");
          if (this.table_json) {
            payload["table_json"] = this.table_json;
          }
          // console.log(payload);
          axios
            .post(this.$store.state.api + this.endpoint, payload, {
              headers: {
                Authorization: "Bearer " + this.$store.state.jwt,
              },
            })
            .then((response) => {
              // console.log(response);
              if (response.data.status === "success") {
                this.info = "Checklist Template Created";
                this.showDismissibleAlert = true;
                this.closeForm();
                this.loading = false;
                this.getConfiguredChecklistData();
              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading = false;
              }
              this.loading = false;
            })
            .catch((err) => {
              this.showDismissibleAlert = true;
              this.info = err;
              this.loading = false;
            });
        }
      }

      // update submit

      if (this.edit && this.selectedTemplate) {
        this.meta = this.tableData;
        let payload = {
          name: this.name,
          msg: this.msg,
          checklist_meta: this.meta,
          template_id: this.selectedTemplate.template_id,
          doc_no: this.doc_no,
          version_no: this.version_no,
          version_date: this.version_date,
          issue_no: this.issue_no,
          issue_date: this.issue_date,
          update_template: true,
        };
        this.loading = true;
        // console.log(payload, "payload");
        if (this.table_json) {
          payload["table_json"] = this.table_json;
        }
        axios
          .post(this.$store.state.api + this.endpoint, payload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            // console.log(response);
            if (response.data.status === "success") {
              this.info = "Checklist Template Created";
              this.showDismissibleAlert = true;
              this.loading = false;
              this.closeForm();

              this.getConfiguredChecklistData();
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },

    deleteFromTableData(item) {
      this.tableData = this.tableData.filter(
        (i) => item.key_text !== i.key_text
      );
    },
    moveUp(index) {
      if (index > 0) {
        //   console.log('Before move up:', this.views.map(view => view.seq));
        const temp = this.tableData[index];
        this.$set(this.tableData, index, this.tableData[index - 1]);
        this.$set(this.tableData, index - 1, temp);
        //   console.log('After move up:', this.views.map(view => view.seq));
      }
    },
    moveDown(index) {
      if (index < this.tableData.length - 1) {
        //  console.log('Before move down:', this.views.map(view => view.seq));
        const temp = this.tableData[index];
        this.$set(this.tableData, index, this.tableData[index + 1]);
        this.$set(this.tableData, index + 1, temp);
        //  console.log('After move down:', this.views.map(view => view.seq));
      }
    },

    addToList() {
      if (this.field_name && this.field_type) {
        const exists = this.tableData.some(
          (item) =>
            item.key_text === this.field_name &&
            item.entry_type === this.field_type
        );

        if (exists) {
          alert("This field name and type are already in the list.");
        } else {
          this.tableData.push({
            key_text: this.field_name,
            entry_type: this.field_type,
            required: this.type_rule,
            meta: { list_id: this.list_id },
          });
          this.list_id = null;
        }
      }
      this.meta = [...this.tableData];
    },

    openDialog() {
      this.dialog = !this.dialog;
    },

    closeForm() {
      this.endpoint = "createChecklistTemplate";
      this.dialog = false;
      this.onReset();
      this.edit = false;
      this.selectedTemplate = null;
      this.tableData = [];
    },

    deleteChecklistTemplate(d) {
      axios
        .post(
          this.$store.state.api + "deleteChecklistTemplate",
          { template_id: d.template_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.info = "Checklist Template Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
            this.getConfiguredChecklistData();
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getConfiguredChecklistData() {
      this.loading = true;
      // console.log("inside..");

      axios
        .get(this.$store.state.api + "checklistTemplates", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          let dbData = [...response.data.data];
          let tempList = [];
          for (let i of dbData) {
            let temp = i;
            temp["created"] = i.version_date
              ? moment(i.created).format("DD-MM-YYYY HH:mm")
              : "-";
            temp["version_date"] = i.version_date
              ? moment(i.version_date).format("DD-MM-YYYY")
              : null;
            temp["issue_date"] = i.issue_date
              ? moment(i.issue_date).format("DD-MM-YYYY")
              : null;
            temp["issue_no"] = i.issue_no ? i.issue_no : "-";
            temp["version_no"] = i.version_no ? i.version_no : "-";
            tempList.push(temp);
          }
          this.templates = tempList;
          if (response.data.status === "success") {
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);

          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },

    editTemplate(item) {
      this.edit = true;
      this.selectedTemplate = item;
      this.name = item.name;
      this.msg = item.msg;
      this.doc_no = item.doc_no;
      this.version_no = item.version_no;
      this.version_date = item.version_date;
      this.issue_no = item.issue_no;
      this.issue_date = item.issue_date;
      this.table_json = item.table_json;
      this.tableData = [];
      axios
        .post(
          this.$store.state.api + "checklistTemplateMetaById",
          { template_id: item.template_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          response.data.data.sort((a, b) => a.seq - b.seq);
          this.tableData = response.data.data;
        });

      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.no-horizontal-scroll {
  overflow: hidden;
}
</style>
