<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row class="mb-4 mt-1" no-gutters v-if="!loading">
      <v-col
        cols="12"
        v-for="(items, tableName) in groupedData"
        :key="tableName"
      >
        <v-card
          class="mb-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
          outlined
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <v-card-title class="text-h6">
            {{ tableName }}
          </v-card-title>
          <v-card-text>
            <!-- <v-data-table
              :headers="generateHeaders(items)"
              :items="generateRows(items)"
              class="elevation-1"
              dense
              :style="{
                border:
                  '1px solid' +
                  $store.getters.getColorPalette().panelBorderColorCode,
              }"
            >
            </v-data-table> -->
            <v-divider :dark="$store.getters.getColorPalette().isDark" />       
            <v-data-table
              :headers="generateHeaders(items)"
              :items="generateRows(items)"
              :item-key="key-value"
              :search="search"
              :single-expand="false"
              :loading="loading"
              loading-text="Loading... Please wait"
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().backgroundColorName"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    >{{ $store.getters.getTextMap().reference }}
                    {{ $store.getters.getTextMap().sheet }}
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$store.getters.getTextMap().search"
                    single-line
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-toolbar>
              </template>

              <template>
                <v-toolbar flat>
                  <v-toolbar-title
                    >{{ $store.getters.getTextMap().reference }}
                    {{ $store.getters.getTextMap().sheet }}
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>


              <template v-slot:item.enture_ed="{ item }">
                <td>
                  <v-btn
                    class="mx-1"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    outlined
                    v-on:click="
                      editRow({
                        meta: item,
                        table_name: tableName,
                        key_value: item.key_value,
                      })
                    "
                    >{{ $store.getters.getTextMap().edit }}

                    <v-icon
                      right
                      :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor"
                      small
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                  </td>
                </template>
              <template v-slot:item.enture_del="{ item }">
                <td>
                  <DeleteConfirmation
                    v-on:confirm="
                      deleteRow({
                        table_name: tableName,
                        key_value: item.key_value,
                      })
                    "
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this Log?"
                  >
                    <v-btn
                      class="mx-1"
                      :color="$store.getters.getColorPalette().accentCode"
                      small
                      text
                      outlined
                    >
                      {{ $store.getters.getTextMap().delete }}

                      <v-icon
                        right
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().deletebtnColor"
                        small
                        >mdi-trash-can</v-icon
                      >
                    </v-btn>
                  </DeleteConfirmation>
                </td>
                 </template>
            </v-data-table>
            <v-divider :dark="$store.getters.getColorPalette().isDark" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
import DeleteConfirmation from "@/components/DeleteConfirmation";

export default {
  name: "ManufacturingLogbookReferenceSheetList",
  components: {
    InfoAlert,
    DeleteConfirmation,
  },
  props: ["refetchData"],
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      expanded: [],
      singleExpand: false,
      search: "",
      dbData: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    // Grouping data by table_name
    groupedData() {
      let grouped = {};
      this.dbData.forEach((item) => {
        if (!grouped[item.table_name]) {
          grouped[item.table_name] = [];
        }
        grouped[item.table_name].push(item);
      });
      return grouped;
    },
  },
  methods: {
    editRow(value) {
      let { ...newObj } = value;
      delete newObj.meta.key_value;
      this.$emit("editRefSheet", newObj);
    },
    deleteRow(value) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "deleteManufacturingLogbookRefSheet",
          value,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getData();
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.info = err;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    getData() {
      this.dbData = [];
      this.loading = true;
      axios
        .get(this.$store.state.api + "manufacturingLogbookRefSheets", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.dbData = response.data.data;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.info = err;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    generateHeaders(items) {
      if (!items.length) return [];
      let temp = [...items];
      temp[0].meta["key_value"] = null;
      let headers = Object.keys(temp[0].meta).map((key) => ({
        text: key,
        value: key,
        align: "start",
        filterable: true,
        class:
          " text-capitalize font-weight-black " +
          this.$store.getters.getColorPalette().tableHeaderColorName,
      }));
      headers.push({
        text: "edit",
        value: "enture_ed",
        filterable: false,
        class:
          " text-capitalize font-weight-black " +
          this.$store.getters.getColorPalette().tableHeaderColorName,
      });
      headers.push({
        text: "delete",
        value: "enture_del",
        filterable: false,
        class:
          " text-capitalize font-weight-black " +
          this.$store.getters.getColorPalette().tableHeaderColorName,
      });

      return headers;
    },
    generateRows(items) {
      return items.map((item) => {
        return {
          ...item.meta,
          key_value: item.key_value,
        };
      });
    },
  },
  watch: {
    refetchData: {
      handler() {
        if (this.refetchData) {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
