var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding",attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_c('v-card',{staticClass:"mb-1 pa-4",style:({
          border:
            '1px solid' +
            _vm.$store.getters.getColorPalette().panelBorderColorCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode,"outlined":""}},[(!_vm.loading)?_c('v-data-table',{staticStyle:{"background":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.dbData,"item-key":"template_id","search":_vm.search,"single-expand":true,"show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().template))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""},on:{"click":function($event){return _vm.editTemplate(item)}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().edit)+" "),_c('v-icon',{attrs:{"right":"","isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().deletebtnColor,"small":""}},[_vm._v("mdi-pencil")])],1),_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this Log?"},on:{"confirm":function($event){return _vm.deleteTemplate(item)}}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().delete)+" "),_c('v-icon',{attrs:{"right":"","isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().deletebtnColor,"small":""}},[_vm._v("mdi-trash-can")])],1)],1)],1)]}}],null,false,1095634646)},[[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().templates))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]],2):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }